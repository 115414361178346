//nito

window._trs = function (syrahItemDiv, callback) {
    syrahItemDiv.setAttribute("class", "row");
    var uri = "gen" + syrahItemDiv.getAttribute("data-syrah-uri");
    window._p(
            {
                element: syrahItemDiv,
                url: uri,
                tipo: 'GET',
                data: {},
                callback: function (_hres) {
                    syrahItemDiv.innerHTML = _hres;
                    if (callback) {
                        callback(syrahItemDiv);
                    }
                }
            }
    );
};
//nito
window._ta = function (container, vertical) {
    var tbody = d.createElement("tbody");
    tbody.setAttribute("data-syrah-uri", container.getAttribute("data-syrah-uri"));


    container.appendChild(tbody);
    window._trs(tbody, function () {
        /*var col3 = d.createElement("div");
        col3.setAttribute("class", "col-3");
        container.appendChild(col3);*/
    });
};
