/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
function _analyticVisita(_refElem, _elem) {
    if (window.ga != undefined) {
        var collectData = {
            hitType: "pageview"
        };

        if (typeof _refElem === 'string' || _refElem instanceof String) {
            collectData.page = _refElem;
        }
        else {
            var grupos = getByClass(_elem, "analitycGroups");
            var _u = getByClass(_elem, "analitycUri").length > 0 ? getByClass(_elem, "analitycUri")[0].innerText : "";
            var _t = getByClass(_elem, "analitycTitle").length > 0 ? getByClass(_elem, "analitycTitle")[0].innerText : "";
            var _a = getByClass(_elem, "analitycAppName").length > 0 ? getByClass(_elem, "analitycAppName")[0].innerText : "";
            collectData.page = _u;
            collectData.title = _t;
            if (grupos.length > 0) {
                collectData.contentGroup1 = grupos[0].innerText;
                if (grupos.length > 1) {
                    collectData.contentGroup2 = grupos[1].innerText;
                    if (grupos.length > 2) {
                        collectData.contentGroup3 = grupos[2].innerText;
                        if (grupos.length > 3) {
                            collectData.contentGroup4 = grupos[3].innerText;
                            if (grupos.length > 4) {
                                collectData.contentGroup5 = grupos[4].innerText;
                            }
                        }
                    }
                }
            }
            collectData.linkid = _refElem != undefined ? _refElem.id : "";
            collectData.appName = _a;
            /// Me fijo en los a que tenga para relacionarlos a este origen

            _elem.querySelectorAll("a").forEach(element => {
                element.addEventListener("click", function () {
                    if (this.getAttribute("href") != "" && this.getAttribute("href") != null) {
                        var cloneCollectDataParent = JSON.parse(JSON.stringify(collectData));
                        cloneCollectDataParent.page = this.getAttribute("href");
                        window.ga("send", cloneCollectDataParent);
                    }

                    return true;
                });
            });
        }
        window.ga("send", collectData);
    }

}