(function (){ var b="",aa="\x00",ba="\n",ca="\n//# sourceURL=",da="\n;return exports});\n//# sourceURL=",k=" ",ea=" &#160;",fa=" onreadystatechange='goog.onScriptLoad_(this, ",ga=" should not be enumerable in Object.prototype.",l='"',ha='");',ia='">\x3c/script>',ja="#",ka="$1",la="%s",q="&",ma="&#0;",na="&#101;",oa="&#39;",pa="&amp;",qa="&gt;",ra="&lt;",sa="&quot;",ta="'",ua="(^",va=")' ",wa=")([a-z])",xa=");",ya=", ",za="-",Aa="-$1",r=".",Ba="..",Ca="...",Da="/",u="0",Ea="0,(function(){",v=": ",w="<",Fa="\x3c/script>",
Ga="<br />",Ha="<br>",Ia='<script type="text/javascript" src="',Ja='<script type="text/javascript">',Ka=">",La=">\x3c/script>",Ma="?",Na="Already loaded ",Pa="American Samoa",Qa="Antigua and Barbuda",Ra="Assertion failed",Sa="BY_WHOLE",x="Bolivia",y="Bosna i Hercegovina",Ta="Botswana",Ua="British Virgin Islands",Va="Cayman Islands",Wa="Christmas Island",Xa="Expected Element but got %s: %s.",Ya="Expected array but got %s: %s.",Za="Expected boolean but got %s: %s.",$a="Expected function but got %s: %s.",
ab="Expected instanceof %s but got %s.",bb="Expected number but got %s: %s.",cb="Expected object but got %s: %s.",db="Expected string but got %s: %s.",eb="Failure",fb="Falkland Islands",z="Ghana",gb="Guin\u00e9e \u00e9quatoriale",hb="Guyane fran\u00e7aise",ib="HEAD",jb="Honduras",kb="Indonesia",lb="Itoophiyaa",mb="JavaScript",nb="Kalaallit Nunaat",ob="Kiribati",pb="Load packages + dependencies - previous: ",qb="Loading css files: ",rb="LocaleNameConstants",sb="Luxembourg",tb="Madagascar",ub="Marshall Islands",
B="Micronesia",vb="Moldova, Republica",wb="Nederlandse Antillen",xb="New Zealand",C="Nigeria",yb="Norfolk Island",zb="Northern Mariana Islands",Ab="Nouvelle-Cal\u00e9donie",D="Papua New Guinea",Bb="Paraguay",Cb="Philippines",Db="Polyn\u00e9sie fran\u00e7aise",Eb="Puerto Rico",Fb="Rep\u00fablica Dominicana",E="Rwanda",Gb="Rywvaneth Unys",Hb="R\u00e9publique centrafricaine",Ib="R\u00e9publique d\u00e9mocratique du Congo",Jb="SCRIPT",Kb="Saint Kitts and Nevis",Lb="Saint Vincent and the Grenadines",Mb=
"Saint-Pierre-et-Miquelon",Nb="Serbia and Montenegro",Ob="Seychelles",Pb="Slovensk\u00e1 republika",Qb="Solomon Islands",F="South Africa",Tb="Svalbard og Jan Mayen",Ub="Swaziland",Vb="S\u00e3o Tom\u00e9 e Pr\u00edncipe",G="S\u00e9n\u00e9gal",Wb="Tanzania",Xb="Timor Leste",H="Tokelau",Yb="Turks and Caicos Islands",I="Tuvalu",J="T\u00fcrkiye",Zb="U.S. Virgin Islands",$b="United Kingdom",ac="United States",bc="United States Minor Outlying Islands",cc="Unknown or Invalid Region",K="Vanuatu",dc="Wallis-et-Futuna",
ec="[object Array]",fc="[object Function]",gc="[object Window]",hc="\\$1",ic="\\s",jc="\\u",kc="\\x",lc="\\x08",mc="]+",L="_",nc="amp",oc="annotatedtimeline",M="array",pc="base.js",qc="boolean",N="browserchart",rc="call",sc="callback after loading ",tc="charts",O="complete",P="corechart",uc="div",vc="document",Q="dygraph",wc="e",xc="en",yc="end loadScript: ",zc="error",R="function",Ac="g",Bc="get",Cc="goog",Dc="goog.loadModule(",Ec='goog.loadModule(function(exports) {"use strict";',Fc='goog.retrieveAndExecModule_("',
Gc="goog_",Hc="google",Ic="google.charts.load",Jc="google.charts.load version ",Kc="gt",Lc="head",Mc="href",Nc="id",Oc="iframe",S="imagechart",Pc="javascript",Qc="link",Rc="load",Sc="load-css-",Tc="loadCSSFile: ",Uc="loadScript: ",Vc="loading css failed: ",Wc="lt",Xc="native code",Yc="none",Zc="null",$c="number",ad="o",T="object",bd="onload",cd="quot",dd="rel",ed="removeAttribute",fd="script",gd="splice",hd="string",id="stylesheet",jd="text/css",kd="text/javascript",U="top",ld="type",V="ui",md="ui_base",
nd="unknown",od="unknown type name",pd="var ",qd="var _evalTest_ = 1;",rd="visualization",sd="webfontloader",td="write",ud="{cssFile}",vd="{language}",wd="{package}",xd="{prefix}",yd="{prefix}/{version}/css/{cssFile}",zd="{prefix}/{version}/third_party/{package}",Ad="{version}",Bd="|[",Cd="})",Dd="~",Ed="\u0080",Fd="\u010cesk\u00e1 republika",Gd="\u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c",Hd="\u041a\u044b\u0440\u0433\u044b\u0437\u0441\u0442\u0430\u043d",Id="\u043c\u043e\u043d\u0433\u043e\u043b\u044c\u0441\u043a\u0438\u0439",
Jd="\u0540\u0561\u0575\u0561\u057d\u057f\u0561\u0576\u056b \u0540\u0561\u0576\u0580\u0561\u057a\u0565\u057f\u0578\u0582\u0569\u056b\u0582\u0576",Kd="\u0627\u0641\u063a\u0627\u0646\u0633\u062a\u0627\u0646",Ld="\u0627\u0644\u0627\u0645\u0627\u0631\u0627\u062a \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0645\u062a\u062d\u062f\u0629",Md="\u0627\u0644\u0635\u062d\u0631\u0627\u0621 \u0627\u0644\u063a\u0631\u0628\u064a\u0629",Nd="\u0627\u0644\u0645\u0645\u0644\u0643\u0629 \u0627\u0644\u0639\u0631\u0628\u064a\u0629 \u0627\u0644\u0633\u0639\u0648\u062f\u064a\u0629",
Od="\u0627\u0644\u0648\u0644\u0627\u064a\u0627\u062a \u0627\u0644\u0645\u062a\u062d\u062f\u0629 \u0627\u0644\u0623\u0645\u0631\u064a\u0643\u064a\u0629",Pd="\u062c\u0632\u0631 \u0627\u0644\u0642\u0645\u0631",Qd="\u067e\u0627\u06a9\u0633\u062a\u0627\u0646",W="\u092d\u093e\u0930\u0924",X="\u12a2\u1275\u12ee\u1335\u12eb",Rd="\uc870\uc120 \ubbfc\uc8fc\uc8fc\uc758 \uc778\ubbfc \uacf5\ud654\uad6d",Sd="\ufffd";function Y(){return function(){}}var Z=Z||{};Z.global=this;Z.N=function(a){return void 0!==a};
Z.ya=function(a,c,d){a=a.split(r);d=d||Z.global;a[0]in d||!d.execScript||d.execScript(pd+a[0]);for(var e;a.length&&(e=a.shift());)!a.length&&Z.N(c)?d[e]=c:d=d[e]?d[e]:d[e]={}};Z.ue=function(a,c){Z.ya(a,c)};Z.H=!0;Z.Cd=xc;Z.qa=!0;Z.dc=!1;Z.Nb=!Z.H;Z.Xa=!1;Z.Gf=function(a){if(Z.Ka())throw Error("goog.provide can not be used within a goog.module.");Z.eb(a)};Z.eb=function(a,c){Z.ya(a,c)};Z.lc=/^[a-zA-Z_$][a-zA-Z0-9._$]*$/;
Z.Na=function(a){if(!Z.h(a)||!a||-1==a.search(Z.lc))throw Error("Invalid module identifier");if(!Z.Ka())throw Error("Module "+a+" has been loaded incorrectly.");if(Z.j.Oa)throw Error("goog.module may only be called once per module.");Z.j.Oa=a};Z.Na.get=Y();Z.Na.Ge=Y();Z.j=null;Z.Ka=function(){return null!=Z.j};Z.Na.va=function(){Z.j.va=!0};Z.Zf=function(a){if(Z.Nb)throw a=a||b,Error("Importing test-only code into non-debug environment"+(a?v+a:r));};Z.Be=Y();
Z.Ec=function(){for(var a=[Hc,tc,Rc],c=Z.global,d;d=a.shift();)if(Z.Ic(c[d]))c=c[d];else return null;return c};Z.Re=function(a,c){var d=c||Z.global,e;for(e in a)d[e]=a[e]};Z.Id=function(a,c,d,e){if(Z.Va){var f;a=a.replace(/\\/g,Da);var g=Z.i;e&&typeof e!==qc||(e=e?{module:Cc}:{});for(var h=0;f=c[h];h++)g.R[f]=a,g.Qa[a]=e.module==Cc;for(e=0;c=d[e];e++)a in g.G||(g.G[a]={}),g.G[a][c]=!0}};Z.yg=!1;Z.zd=!0;Z.uf=function(a){Z.global.console&&Z.global.console.error(a)};Z.Tf=Y();Z.F=b;Z.Bf=Y();
Z.Hd=function(){throw Error("unimplemented abstract method");};Z.Jd=function(a){a.Fe=function(){if(a.tb)return a.tb;Z.H&&(Z.ub[Z.ub.length]=a);return a.tb=new a}};Z.ub=[];Z.Ub=!0;Z.bc=Z.H;Z.Uc={};Z.Va=!1;
Z.Va&&(Z.i={Qa:{},R:{},G:{},Gb:{},oa:{},W:{}},Z.rb=function(){var a=Z.global.document;return null!=a&&td in a},Z.zc=function(){if(Z.N(Z.global.Kb))Z.F=Z.global.Kb;else if(Z.rb())for(var a=Z.global.document.getElementsByTagName(Jb),c=a.length-1;0<=c;--c){var d=a[c].src,e=d.lastIndexOf(Ma),e=-1==e?d.length:e;if(d.substr(e-7,7)==pc){Z.F=d.substr(0,e-7);break}}},Z.Ga=function(a,c){(Z.global.rd||Z.od)(a,c)&&(Z.i.oa[a]=!0)},Z.Tb=!(Z.global.atob||!Z.global.document||!Z.global.document.all),Z.Gc=function(a){Z.Ga(b,
Fc+a+ha)&&(Z.i.oa[a]=!0)},Z.Ra=[],Z.Ag=function(a,c){return Z.Ub&&Z.N(Z.global.JSON)?Dc+Z.global.JSON.stringify(c+ca+a+ba)+xa:Ec+c+da+a+ba},Z.Sc=function(){var a=Z.Ra.length;if(0<a){var c=Z.Ra;Z.Ra=[];for(var d=0;d<a;d++)Z.yb(c[d])}},Z.vf=function(a){Z.vb(a)&&Z.mc(a)&&Z.yb(Z.F+Z.Fa(a))},Z.vb=function(a){return(a=Z.Fa(a))&&Z.i.Qa[a]?Z.F+a in Z.i.W:!1},Z.mc=function(a){if((a=Z.Fa(a))&&a in Z.i.G)for(var c in Z.i.G[a])if(!Z.Mc(c)&&!Z.vb(c))return!1;return!0},Z.yb=function(a){if(a in Z.i.W){var c=Z.i.W[a];
delete Z.i.W[a];Z.Fc(c)}},Z.tf=Y(),Z.sf=function(a){var c=Z.j;try{Z.j={Oa:void 0,va:!1};var d;if(Z.wb(a))d=a.call(Z.global,{});else if(Z.h(a))d=Z.Qc.call(Z.global,a);else throw Error("Invalid module definition");var e=Z.j.Oa;if(!Z.h(e)||!e)throw Error('Invalid module name "'+e+l);Z.j.va?Z.eb(e,d):Z.bc&&Object.seal&&Object.seal(d);Z.Uc[e]=d}finally{Z.j=c}},Z.Qc=function(a){eval(a);return{}},Z.md=function(a){Z.global.document.write(Ia+a+ia)},Z.nc=function(a){var c=Z.global.document,d=c.createElement(fd);
d.type=kd;d.src=a;d.defer=!1;d.async=!1;c.head.appendChild(d)},Z.od=function(a,c){if(Z.rb()){var d=Z.global.document;if(!Z.Xa&&d.readyState==O){if(/\bdeps.js$/.test(a))return!1;throw Error('Cannot write "'+a+'" after document load');}var e=Z.Tb;void 0===c?e?(e=fa+ ++Z.xb+va,d.write(Ia+a+l+e+La)):Z.Xa?Z.nc(a):Z.md(a):d.write(Ja+c+Fa);return!0}return!1},Z.xb=0,Z.Df=function(a,c){a.readyState==O&&Z.xb==c&&Z.Sc();return!0},Z.Bg=function(a){function c(a){if(!(a in f.oa||a in f.Gb)){f.Gb[a]=!0;if(a in f.G)for(var g in f.G[a])if(!Z.Mc(g))if(g in
f.R)c(f.R[g]);else throw Error("Undefined nameToPath for "+g);a in e||(e[a]=!0,d.push(a))}}var d=[],e={},f=Z.i;c(a);for(a=0;a<d.length;a++){var g=d[a];Z.i.oa[g]=!0}var h=Z.j;Z.j=null;for(a=0;a<d.length;a++)if(g=d[a])f.Qa[g]?Z.Gc(Z.F+g):Z.Ga(Z.F+g);else throw Z.j=h,Error("Undefined script input");Z.j=h},Z.Fa=function(a){return a in Z.i.R?Z.i.R[a]:null},Z.zc(),Z.global.td||Z.Ga(Z.F+"deps.js"));
Z.yf=function(a){a=a.split(Da);for(var c=0;c<a.length;)a[c]==r?a.splice(c,1):c&&a[c]==Ba&&a[c-1]&&a[c-1]!=Ba?a.splice(--c,2):c++;return a.join(Da)};Z.rf=function(a){if(Z.global.Lb)return Z.global.Lb(a);var c=new Z.global.XMLHttpRequest;c.open(Bc,a,!1);c.send();return c.responseText};Z.Uf=Y();
Z.s=function(a){var c=typeof a;if(c==T)if(a){if(a instanceof Array)return M;if(a instanceof Object)return c;var d=Object.prototype.toString.call(a);if(d==gc)return T;if(d==ec||typeof a.length==$c&&"undefined"!=typeof a.splice&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable(gd))return M;if(d==fc||"undefined"!=typeof a.call&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable(rc))return R}else return Zc;else if(c==R&&"undefined"==typeof a.call)return T;return c};
Z.gf=function(a){return null===a};Z.Ic=function(a){return null!=a};Z.isArray=function(a){return Z.s(a)==M};Z.Ia=function(a){var c=Z.s(a);return c==M||c==T&&typeof a.length==$c};Z.bf=function(a){return Z.Z(a)&&typeof a.getFullYear==R};Z.h=function(a){return typeof a==hd};Z.Hc=function(a){return typeof a==qc};Z.Lc=function(a){return typeof a==$c};Z.wb=function(a){return Z.s(a)==R};Z.Z=function(a){var c=typeof a;return c==T&&null!=a||c==R};Z.ob=function(a){return a[Z.D]||(a[Z.D]=++Z.jd)};Z.Se=function(a){return!!a[Z.D]};
Z.ad=function(a){null!==a&&ed in a&&a.removeAttribute(Z.D);try{delete a[Z.D]}catch(c){}};Z.D="closure_uid_"+(1E9*Math.random()>>>0);Z.jd=0;Z.De=Z.ob;Z.Rf=Z.ad;Z.tc=function(a){var c=Z.s(a);if(c==T||c==M){if(a.clone)return a.clone();var c=c==M?[]:{},d;for(d in a)c[d]=Z.tc(a[d]);return c}return a};Z.rc=function(a,c,d){return a.call.apply(a.bind,arguments)};
Z.qc=function(a,c,d){if(!a)throw Error();if(2<arguments.length){var e=Array.prototype.slice.call(arguments,2);return function(){var d=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(d,e);return a.apply(c,d)}}return function(){return a.apply(c,arguments)}};Z.bind=function(a,c,d){Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf(Xc)?Z.bind=Z.rc:Z.bind=Z.qc;return Z.bind.apply(null,arguments)};
Z.Yc=function(a,c){var d=Array.prototype.slice.call(arguments,1);return function(){var c=d.slice();c.push.apply(c,arguments);return a.apply(this,c)}};Z.wf=function(a,c){for(var d in c)a[d]=c[d]};Z.now=Z.qa&&Date.now||function(){return+new Date};
Z.Fc=function(a){if(Z.global.execScript)Z.global.execScript(a,mb);else if(Z.global.eval){if(null==Z.X)if(Z.global.eval(qd),"undefined"!=typeof Z.global._evalTest_){try{delete Z.global._evalTest_}catch(e){}Z.X=!0}else Z.X=!1;if(Z.X)Z.global.eval(a);else{var c=Z.global.document,d=c.createElement(Jb);d.type=kd;d.defer=!1;d.appendChild(c.createTextNode(a));c.body.appendChild(d);c.body.removeChild(d)}}else throw Error("goog.globalEval not available");};Z.X=null;
Z.Ce=function(a,c){function d(a){a=a.split(za);for(var c=[],d=0;d<a.length;d++)c.push(e(a[d]));return c.join(za)}function e(a){return Z.fb[a]||a}var f;f=Z.fb?Z.wc==Sa?e:d:function(a){return a};return c?a+za+f(c):f(a)};Z.Vf=function(a,c){Z.fb=a;Z.wc=c};Z.Je=function(a,c){c&&(a=a.replace(/\{\$([^}]+)}/g,function(a,e){return null!=c&&e in c?c[e]:a}));return a};Z.Ke=function(a){return a};Z.Aa=function(a,c){Z.ya(a,c,void 0)};Z.ze=function(a,c,d){a[c]=d};
Z.Ha=function(a,c){function d(){}d.prototype=c.prototype;a.ma=c.prototype;a.prototype=new d;a.prototype.constructor=a;a.pc=function(a,d,g){for(var h=Array(arguments.length-2),m=2;m<arguments.length;m++)h[m-2]=arguments[m];return c.prototype[d].apply(a,h)}};
Z.pc=function(a,c,d){var e=arguments.callee.caller;if(Z.dc||Z.H&&!e)throw Error("arguments.caller not defined.  goog.base() cannot be used with strict mode code. See http://www.ecma-international.org/ecma-262/5.1/#sec-C");if(e.ma){for(var f=Array(arguments.length-1),g=1;g<arguments.length;g++)f[g-1]=arguments[g];return e.ma.constructor.apply(a,f)}f=Array(arguments.length-2);for(g=2;g<arguments.length;g++)f[g-2]=arguments[g];for(var g=!1,h=a.constructor;h;h=h.ma&&h.ma.constructor)if(h.prototype[c]===
e)g=!0;else if(g)return h.prototype[c].apply(a,f);if(a[c]===e)return a.constructor.prototype[c].apply(a,f);throw Error("goog.base called from a method of one name to a method of a different name");};Z.scope=function(a){if(Z.Ka())throw Error("goog.scope is not supported within a goog.module.");a.call(Z.global)};
Z.u=function(a,c){var d=c.constructor,e=c.dd;d&&d!=Object.prototype.constructor||(d=function(){throw Error("cannot instantiate an interface (no constructor defined).");});d=Z.u.uc(d,a);a&&Z.Ha(d,a);delete c.constructor;delete c.dd;Z.u.Za(d.prototype,c);null!=e&&(e instanceof Function?e(d):Z.u.Za(d,e));return d};Z.u.ac=Z.H;
Z.u.uc=function(a,c){if(Z.u.ac&&Object.seal instanceof Function){if(c&&c.prototype&&c.prototype[Z.jc])return a;var d=function(){var c=a.apply(this,arguments)||this;c[Z.D]=c[Z.D];this.constructor===d&&Object.seal(c);return c};return d}return a};Z.u.Ya="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
Z.u.Za=function(a,c){for(var d in c)Object.prototype.hasOwnProperty.call(c,d)&&(a[d]=c[d]);for(var e=0;e<Z.u.Ya.length;e++)d=Z.u.Ya[e],Object.prototype.hasOwnProperty.call(c,d)&&(a[d]=c[d])};Z.gg=Y();Z.jc="goog_defineClass_legacy_unsealable";Z.debug={};Z.debug.Error=function(a){if(Error.captureStackTrace)Error.captureStackTrace(this,Z.debug.Error);else{var c=Error().stack;c&&(this.stack=c)}a&&(this.message=String(a))};Z.Ha(Z.debug.Error,Error);Z.debug.Error.prototype.name="CustomError";Z.gb={};Z.gb.Zb={Ob:1,pd:2,Gd:3,qd:4,Bd:5,Ad:6,Fd:7,ud:8,wd:9,yd:10,xd:11,Dd:12};Z.c={};Z.c.Wa=!1;Z.c.Qb=!1;Z.c.kc={Xb:"\u00a0"};Z.c.startsWith=function(a,c){return 0==a.lastIndexOf(c,0)};Z.c.endsWith=function(a,c){var d=a.length-c.length;return 0<=d&&a.indexOf(c,d)==d};Z.c.je=function(a,c){return 0==Z.c.cb(c,a.substr(0,c.length))};Z.c.fe=function(a,c){return 0==Z.c.cb(c,a.substr(a.length-c.length,c.length))};Z.c.ge=function(a,c){return a.toLowerCase()==c.toLowerCase()};
Z.c.ed=function(a,c){for(var d=a.split(la),e=b,f=Array.prototype.slice.call(arguments,1);f.length&&1<d.length;)e+=d.shift()+f.shift();return e+d.join(la)};Z.c.le=function(a){return a.replace(/[\s\xa0]+/g,k).replace(/^\s+|\s+$/g,b)};Z.c.Ja=function(a){return/^[\s\xa0]*$/.test(a)};Z.c.df=function(a){return 0==a.length};Z.c.Jc=Z.c.Ja;Z.c.Kc=function(a){return Z.c.Ja(Z.c.Vc(a))};Z.c.cf=Z.c.Kc;Z.c.$e=function(a){return!/[^\t\n\r ]/.test(a)};Z.c.Ye=function(a){return!/[^a-zA-Z]/.test(a)};Z.c.hf=function(a){return!/[^0-9]/.test(a)};
Z.c.Ze=function(a){return!/[^a-zA-Z0-9]/.test(a)};Z.c.lf=function(a){return a==k};Z.c.mf=function(a){return 1==a.length&&a>=k&&a<=Dd||a>=Ed&&a<=Sd};Z.c.eg=function(a){return a.replace(/(\r\n|\r|\n)+/g,k)};Z.c.Yd=function(a){return a.replace(/(\r\n|\r|\n)/g,ba)};Z.c.Af=function(a){return a.replace(/\xa0|\s/g,k)};Z.c.zf=function(a){return a.replace(/\xa0|[ \t]+/g,k)};Z.c.ke=function(a){return a.replace(/[\t\r\n ]+/g,k).replace(/^[\t\r\n ]+|[\t\r\n ]+$/g,b)};
Z.c.trim=Z.qa&&String.prototype.trim?function(a){return a.trim()}:function(a){return a.replace(/^[\s\xa0]+|[\s\xa0]+$/g,b)};Z.c.trimLeft=function(a){return a.replace(/^[\s\xa0]+/,b)};Z.c.trimRight=function(a){return a.replace(/[\s\xa0]+$/,b)};Z.c.cb=function(a,c){var d=String(a).toLowerCase(),e=String(c).toLowerCase();return d<e?-1:d==e?0:1};
Z.c.Ab=function(a,c,d){if(a==c)return 0;if(!a)return-1;if(!c)return 1;for(var e=a.toLowerCase().match(d),f=c.toLowerCase().match(d),g=Math.min(e.length,f.length),h=0;h<g;h++){d=e[h];var m=f[h];if(d!=m)return a=parseInt(d,10),!isNaN(a)&&(c=parseInt(m,10),!isNaN(c)&&a-c)?a-c:d<m?-1:1}return e.length!=f.length?e.length-f.length:a<c?-1:1};Z.c.We=function(a,c){return Z.c.Ab(a,c,/\d+|\D+/g)};Z.c.Cc=function(a,c){return Z.c.Ab(a,c,/\d+|\.\d+|\D+/g)};Z.c.Cf=Z.c.Cc;Z.c.xg=function(a){return encodeURIComponent(String(a))};
Z.c.wg=function(a){return decodeURIComponent(a.replace(/\+/g,k))};Z.c.Wc=function(a,c){return a.replace(/(\r\n|\r|\n)/g,c?Ga:Ha)};Z.c.pb=function(a){if(!Z.c.Ib.test(a))return a;-1!=a.indexOf(q)&&(a=a.replace(Z.c.Jb,pa));-1!=a.indexOf(w)&&(a=a.replace(Z.c.Wb,ra));-1!=a.indexOf(Ka)&&(a=a.replace(Z.c.Rb,qa));-1!=a.indexOf(l)&&(a=a.replace(Z.c.$b,sa));-1!=a.indexOf(ta)&&(a=a.replace(Z.c.cc,oa));-1!=a.indexOf(aa)&&(a=a.replace(Z.c.Yb,ma));Z.c.Wa&&-1!=a.indexOf(wc)&&(a=a.replace(Z.c.Pb,na));return a};
Z.c.Jb=/&/g;Z.c.Wb=/</g;Z.c.Rb=/>/g;Z.c.$b=/"/g;Z.c.cc=/'/g;Z.c.Yb=/\x00/g;Z.c.Pb=/e/g;Z.c.Ib=Z.c.Wa?/[\x00&<>"'e]/:/[\x00&<>"']/;Z.c.Db=function(a){return Z.c.contains(a,q)?!Z.c.Qb&&vc in Z.global?Z.c.Eb(a):Z.c.kd(a):a};Z.c.vg=function(a,c){return Z.c.contains(a,q)?Z.c.Eb(a,c):a};
Z.c.Eb=function(a,c){var d={"&amp;":q,"&lt;":w,"&gt;":Ka,"&quot;":l},e;e=c?c.createElement(uc):Z.global.document.createElement(uc);return a.replace(Z.c.Sb,function(a,c){var h=d[a];if(h)return h;if(c.charAt(0)==ja){var m=Number(u+c.substr(1));isNaN(m)||(h=String.fromCharCode(m))}h||(e.innerHTML=a+k,h=e.firstChild.nodeValue.slice(0,-1));return d[a]=h})};
Z.c.kd=function(a){return a.replace(/&([^;]+);/g,function(a,d){switch(d){case nc:return q;case Wc:return w;case Kc:return Ka;case cd:return l;default:if(d.charAt(0)==ja){var e=Number(u+d.substr(1));if(!isNaN(e))return String.fromCharCode(e)}return a}})};Z.c.Sb=/&([^;\s<&]+);?/g;Z.c.zg=function(a,c){return Z.c.Wc(a.replace(/  /g,ea),c)};Z.c.Ff=function(a){return a.replace(/(^|[\n ]) /g,ka+Z.c.kc.Xb)};
Z.c.fg=function(a,c){for(var d=c.length,e=0;e<d;e++){var f=1==d?c:c.charAt(e);if(a.charAt(0)==f&&a.charAt(a.length-1)==f)return a.substring(1,a.length-1)}return a};Z.c.truncate=function(a,c,d){d&&(a=Z.c.Db(a));a.length>c&&(a=a.substring(0,c-3)+Ca);d&&(a=Z.c.pb(a));return a};
Z.c.rg=function(a,c,d,e){d&&(a=Z.c.Db(a));if(e&&a.length>c)e>c&&(e=c),a=a.substring(0,c-e)+Ca+a.substring(a.length-e);else if(a.length>c){e=Math.floor(c/2);var f=a.length-e;a=a.substring(0,e+c%2)+Ca+a.substring(f)}d&&(a=Z.c.pb(a));return a};Z.c.Ua={"\x00":"\\0","\b":"\\b","\f":"\\f","\n":"\\n","\r":"\\r","\t":"\\t","\x0B":"\\x0B",'"':'\\"',"\\":"\\\\","<":w};Z.c.$={"'":"\\'"};
Z.c.quote=function(a){a=String(a);for(var c=[l],d=0;d<a.length;d++){var e=a.charAt(d),f=e.charCodeAt(0);c[d+1]=Z.c.Ua[e]||(31<f&&127>f?e:Z.c.hb(e))}c.push(l);return c.join(b)};Z.c.ye=function(a){for(var c=[],d=0;d<a.length;d++)c[d]=Z.c.hb(a.charAt(d));return c.join(b)};
Z.c.hb=function(a){if(a in Z.c.$)return Z.c.$[a];if(a in Z.c.Ua)return Z.c.$[a]=Z.c.Ua[a];var c=a,d=a.charCodeAt(0);if(31<d&&127>d)c=a;else{if(256>d){if(c=kc,16>d||256<d)c+=u}else c=jc,4096>d&&(c+=u);c+=d.toString(16).toUpperCase()}return Z.c.$[a]=c};Z.c.contains=function(a,c){return-1!=a.indexOf(c)};Z.c.$d=function(a,c){return Z.c.contains(a.toLowerCase(),c.toLowerCase())};Z.c.qe=function(a,c){return a&&c?a.split(c).length-1:0};
Z.c.S=function(a,c,d){var e=a;0<=c&&c<a.length&&0<d&&(e=a.substr(0,c)+a.substr(c+d,a.length-c-d));return e};Z.c.remove=function(a,c){var d=new RegExp(Z.c.Sa(c),b);return a.replace(d,b)};Z.c.Of=function(a,c){var d=new RegExp(Z.c.Sa(c),Ac);return a.replace(d,b)};Z.c.Sa=function(a){return String(a).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g,hc).replace(/\x08/g,lc)};Z.c.repeat=String.prototype.repeat?function(a,c){return a.repeat(c)}:function(a,c){return Array(c+1).join(a)};
Z.c.Ef=function(a,c,d){a=Z.N(d)?a.toFixed(d):String(a);d=a.indexOf(r);-1==d&&(d=a.length);return Z.c.repeat(u,Math.max(0,c-d))+a};Z.c.Vc=function(a){return null==a?b:String(a)};Z.c.Xd=function(a){return Array.prototype.join.call(arguments,b)};Z.c.Ne=function(){return Math.floor(2147483648*Math.random()).toString(36)+Math.abs(Math.floor(2147483648*Math.random())^Z.now()).toString(36)};
Z.c.oe=function(a,c){for(var d=0,e=Z.c.trim(String(a)).split(r),f=Z.c.trim(String(c)).split(r),g=Math.max(e.length,f.length),h=0;0==d&&h<g;h++){var m=e[h]||b,n=f[h]||b,p=/(\d*)(\D*)/g,Oa=/(\d*)(\D*)/g;do{var A=p.exec(m)||[b,b,b],t=Oa.exec(n)||[b,b,b];if(0==A[0].length&&0==t[0].length)break;d=Z.c.ra(0==A[1].length?0:parseInt(A[1],10),0==t[1].length?0:parseInt(t[1],10))||Z.c.ra(0==A[2].length,0==t[2].length)||Z.c.ra(A[2],t[2])}while(0==d)}return d};Z.c.ra=function(a,c){return a<c?-1:a>c?1:0};
Z.c.Te=function(a){for(var c=0,d=0;d<a.length;++d)c=31*c+a.charCodeAt(d)>>>0;return c};Z.c.ld=2147483648*Math.random()|0;Z.c.re=function(){return Gc+Z.c.ld++};Z.c.jg=function(a){var c=Number(a);return 0==c&&Z.c.Ja(a)?NaN:c};Z.c.ef=function(a){return/^[a-z]+([A-Z][a-z]*)*$/.test(a)};Z.c.nf=function(a){return/^([A-Z][a-z]*)+$/.test(a)};Z.c.hg=function(a){return String(a).replace(/\-([a-z])/g,function(a,d){return d.toUpperCase()})};Z.c.pg=function(a){return String(a).replace(/([A-Z])/g,Aa).toLowerCase()};
Z.c.qg=function(a,c){var d=Z.h(c)?Z.c.Sa(c):ic;return a.replace(new RegExp(ua+(d?Bd+d+mc:b)+wa,Ac),function(a,c,d){return c+d.toUpperCase()})};Z.c.Zd=function(a){return String(a.charAt(0)).toUpperCase()+String(a.substr(1)).toLowerCase()};Z.c.parseInt=function(a){isFinite(a)&&(a=String(a));return Z.h(a)?/^\s*-?0x/i.test(a)?parseInt(a,16):parseInt(a,10):NaN};Z.c.cg=function(a,c,d){a=a.split(c);for(var e=[];0<d&&a.length;)e.push(a.shift()),d--;a.length&&e.push(a.join(c));return e};
Z.c.we=function(a,c){var d=[],e=[];if(a==c)return 0;if(!a.length||!c.length)return Math.max(a.length,c.length);for(var f=0;f<c.length+1;f++)d[f]=f;for(f=0;f<a.length;f++){e[0]=f+1;for(var g=0;g<c.length;g++)e[g+1]=Math.min(e[g]+1,d[g+1]+1,d[g]+Number(a[f]!=c[g]));for(g=0;g<d.length;g++)d[g]=e[g]}return e[c.length]};Z.g={};Z.g.m=Z.H;Z.g.U=function(a,c){c.unshift(a);Z.debug.Error.call(this,Z.c.ed.apply(null,c));c.shift()};Z.Ha(Z.g.U,Z.debug.Error);Z.g.U.prototype.name="AssertionError";Z.g.Mb=function(a){throw a;};Z.g.xa=Z.g.Mb;Z.g.A=function(a,c,d,e){var f=Ra;if(d)var f=f+(v+d),g=e;else a&&(f+=v+a,g=c);a=new Z.g.U(b+f,g||[]);Z.g.xa(a)};Z.g.Wf=function(a){Z.g.m&&(Z.g.xa=a)};Z.g.assert=function(a,c,d){Z.g.m&&!a&&Z.g.A(b,null,c,Array.prototype.slice.call(arguments,2));return a};
Z.g.Ba=function(a,c){Z.g.m&&Z.g.xa(new Z.g.U(eb+(a?v+a:b),Array.prototype.slice.call(arguments,1)))};Z.g.Pd=function(a,c,d){Z.g.m&&!Z.Lc(a)&&Z.g.A(bb,[Z.s(a),a],c,Array.prototype.slice.call(arguments,2));return a};Z.g.Sd=function(a,c,d){Z.g.m&&!Z.h(a)&&Z.g.A(db,[Z.s(a),a],c,Array.prototype.slice.call(arguments,2));return a};Z.g.Nd=function(a,c,d){Z.g.m&&!Z.wb(a)&&Z.g.A($a,[Z.s(a),a],c,Array.prototype.slice.call(arguments,2));return a};
Z.g.Qd=function(a,c,d){Z.g.m&&!Z.Z(a)&&Z.g.A(cb,[Z.s(a),a],c,Array.prototype.slice.call(arguments,2));return a};Z.g.Kd=function(a,c,d){Z.g.m&&!Z.isArray(a)&&Z.g.A(Ya,[Z.s(a),a],c,Array.prototype.slice.call(arguments,2));return a};Z.g.Ld=function(a,c,d){Z.g.m&&!Z.Hc(a)&&Z.g.A(Za,[Z.s(a),a],c,Array.prototype.slice.call(arguments,2));return a};Z.g.Md=function(a,c,d){!Z.g.m||Z.Z(a)&&a.nodeType==Z.gb.Zb.Ob||Z.g.A(Xa,[Z.s(a),a],c,Array.prototype.slice.call(arguments,2));return a};
Z.g.Od=function(a,c,d,e){!Z.g.m||a instanceof c||Z.g.A(ab,[Z.g.mb(c),Z.g.mb(a)],d,Array.prototype.slice.call(arguments,3));return a};Z.g.Rd=function(){for(var a in Object.prototype)Z.g.Ba(a+ga)};Z.g.mb=function(a){return a instanceof Function?a.displayName||a.name||od:a instanceof Object?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):null===a?Zc:typeof a};Z.f={};Z.w=Z.qa;Z.f.v=!1;Z.f.Zc=function(a){return a[a.length-1]};Z.f.pf=Z.f.Zc;Z.f.indexOf=Z.w&&(Z.f.v||Array.prototype.indexOf)?function(a,c,d){return Array.prototype.indexOf.call(a,c,d)}:function(a,c,d){d=null==d?0:0>d?Math.max(0,a.length+d):d;if(Z.h(a))return Z.h(c)&&1==c.length?a.indexOf(c,d):-1;for(;d<a.length;d++)if(d in a&&a[d]===c)return d;return-1};
Z.f.lastIndexOf=Z.w&&(Z.f.v||Array.prototype.lastIndexOf)?function(a,c,d){return Array.prototype.lastIndexOf.call(a,c,null==d?a.length-1:d)}:function(a,c,d){d=null==d?a.length-1:d;0>d&&(d=Math.max(0,a.length+d));if(Z.h(a))return Z.h(c)&&1==c.length?a.lastIndexOf(c,d):-1;for(;0<=d;d--)if(d in a&&a[d]===c)return d;return-1};
Z.f.forEach=Z.w&&(Z.f.v||Array.prototype.forEach)?function(a,c,d){Array.prototype.forEach.call(a,c,d)}:function(a,c,d){for(var e=a.length,f=Z.h(a)?a.split(b):a,g=0;g<e;g++)g in f&&c.call(d,f[g],g,a)};Z.f.ib=function(a,c){for(var d=Z.h(a)?a.split(b):a,e=a.length-1;0<=e;--e)e in d&&c.call(void 0,d[e],e,a)};
Z.f.filter=Z.w&&(Z.f.v||Array.prototype.filter)?function(a,c,d){return Array.prototype.filter.call(a,c,d)}:function(a,c,d){for(var e=a.length,f=[],g=0,h=Z.h(a)?a.split(b):a,m=0;m<e;m++)if(m in h){var n=h[m];c.call(d,n,m,a)&&(f[g++]=n)}return f};Z.f.map=Z.w&&(Z.f.v||Array.prototype.map)?function(a,c,d){return Array.prototype.map.call(a,c,d)}:function(a,c,d){for(var e=a.length,f=Array(e),g=Z.h(a)?a.split(b):a,h=0;h<e;h++)h in g&&(f[h]=c.call(d,g[h],h,a));return f};
Z.f.reduce=Z.w&&(Z.f.v||Array.prototype.reduce)?function(a,c,d,e){e&&(c=Z.bind(c,e));return Array.prototype.reduce.call(a,c,d)}:function(a,c,d,e){var f=d;Z.f.forEach(a,function(d,h){f=c.call(e,f,d,h,a)});return f};Z.f.reduceRight=Z.w&&(Z.f.v||Array.prototype.reduceRight)?function(a,c,d,e){e&&(c=Z.bind(c,e));return Array.prototype.reduceRight.call(a,c,d)}:function(a,c,d,e){var f=d;Z.f.ib(a,function(d,h){f=c.call(e,f,d,h,a)});return f};
Z.f.some=Z.w&&(Z.f.v||Array.prototype.some)?function(a,c,d){return Array.prototype.some.call(a,c,d)}:function(a,c,d){for(var e=a.length,f=Z.h(a)?a.split(b):a,g=0;g<e;g++)if(g in f&&c.call(d,f[g],g,a))return!0;return!1};Z.f.every=Z.w&&(Z.f.v||Array.prototype.every)?function(a,c,d){return Array.prototype.every.call(a,c,d)}:function(a,c,d){for(var e=a.length,f=Z.h(a)?a.split(b):a,g=0;g<e;g++)if(g in f&&!c.call(d,f[g],g,a))return!1;return!0};
Z.f.count=function(a,c,d){var e=0;Z.f.forEach(a,function(a,g,h){c.call(d,a,g,h)&&++e},d);return e};Z.f.find=function(a,c,d){c=Z.f.findIndex(a,c,d);return 0>c?null:Z.h(a)?a.charAt(c):a[c]};Z.f.findIndex=function(a,c,d){for(var e=a.length,f=Z.h(a)?a.split(b):a,g=0;g<e;g++)if(g in f&&c.call(d,f[g],g,a))return g;return-1};Z.f.Ae=function(a,c,d){c=Z.f.Ac(a,c,d);return 0>c?null:Z.h(a)?a.charAt(c):a[c]};
Z.f.Ac=function(a,c,d){for(var e=Z.h(a)?a.split(b):a,f=a.length-1;0<=f;f--)if(f in e&&c.call(d,e[f],f,a))return f;return-1};Z.f.contains=function(a,c){return 0<=Z.f.indexOf(a,c)};Z.f.Jc=function(a){return 0==a.length};Z.f.clear=function(a){if(!Z.isArray(a))for(var c=a.length-1;0<=c;c--)delete a[c];a.length=0};Z.f.Ue=function(a,c){Z.f.contains(a,c)||a.push(c)};Z.f.sb=function(a,c,d){Z.f.splice(a,d,0,c)};Z.f.Ve=function(a,c,d){Z.Yc(Z.f.splice,a,d,0).apply(null,c)};
Z.f.insertBefore=function(a,c,d){var e;2==arguments.length||0>(e=Z.f.indexOf(a,d))?a.push(c):Z.f.sb(a,c,e)};Z.f.remove=function(a,c){var d=Z.f.indexOf(a,c),e;(e=0<=d)&&Z.f.S(a,d);return e};Z.f.S=function(a,c){return 1==Array.prototype.splice.call(a,c,1).length};Z.f.Sf=function(a,c,d){c=Z.f.findIndex(a,c,d);return 0<=c?(Z.f.S(a,c),!0):!1};Z.f.Pf=function(a,c,d){var e=0;Z.f.ib(a,function(f,g){c.call(d,f,g,a)&&Z.f.S(a,g)&&e++});return e};
Z.f.concat=function(a){return Array.prototype.concat.apply(Array.prototype,arguments)};Z.f.join=function(a){return Array.prototype.concat.apply(Array.prototype,arguments)};Z.f.hd=function(a){var c=a.length;if(0<c){for(var d=Array(c),e=0;e<c;e++)d[e]=a[e];return d}return[]};Z.f.clone=Z.f.hd;Z.f.extend=function(a,c){for(var d=1;d<arguments.length;d++){var e=arguments[d];if(Z.Ia(e)){var f=a.length||0,g=e.length||0;a.length=f+g;for(var h=0;h<g;h++)a[f+h]=e[h]}else a.push(e)}};
Z.f.splice=function(a,c,d,e){return Array.prototype.splice.apply(a,Z.f.slice(arguments,1))};Z.f.slice=function(a,c,d){return 2>=arguments.length?Array.prototype.slice.call(a,c):Array.prototype.slice.call(a,c,d)};Z.f.Qf=function(a,c,d){function e(a){return Z.Z(a)?ad+Z.ob(a):(typeof a).charAt(0)+a}c=c||a;d=d||e;for(var f={},g=0,h=0;h<a.length;){var m=a[h++],n=d(m);Object.prototype.hasOwnProperty.call(f,n)||(f[n]=!0,c[g++]=m)}c.length=g};Z.f.$a=function(a,c,d){return Z.f.bb(a,d||Z.f.B,!1,c)};
Z.f.Vd=function(a,c,d){return Z.f.bb(a,c,!0,void 0,d)};Z.f.bb=function(a,c,d,e,f){for(var g=0,h=a.length,m;g<h;){var n=g+h>>1,p;p=d?c.call(f,a[n],n,a):c(e,a[n]);0<p?g=n+1:(h=n,m=!p)}return m?g:~g};Z.f.sort=function(a,c){a.sort(c||Z.f.B)};Z.f.dg=function(a,c){for(var d=Array(a.length),e=0;e<a.length;e++)d[e]={index:e,value:a[e]};var f=c||Z.f.B;Z.f.sort(d,function(a,c){return f(a.value,c.value)||a.index-c.index});for(e=0;e<a.length;e++)a[e]=d[e].value};
Z.f.cd=function(a,c,d){var e=d||Z.f.B;Z.f.sort(a,function(a,d){return e(c(a),c(d))})};Z.f.ag=function(a,c,d){Z.f.cd(a,function(a){return a[c]},d)};Z.f.kf=function(a,c,d){c=c||Z.f.B;for(var e=1;e<a.length;e++){var f=c(a[e-1],a[e]);if(0<f||0==f&&d)return!1}return!0};Z.f.xe=function(a,c,d){if(!Z.Ia(a)||!Z.Ia(c)||a.length!=c.length)return!1;var e=a.length;d=d||Z.f.xc;for(var f=0;f<e;f++)if(!d(a[f],c[f]))return!1;return!0};
Z.f.me=function(a,c,d){d=d||Z.f.B;for(var e=Math.min(a.length,c.length),f=0;f<e;f++){var g=d(a[f],c[f]);if(0!=g)return g}return Z.f.B(a.length,c.length)};Z.f.B=function(a,c){return a>c?1:a<c?-1:0};Z.f.Xe=function(a,c){return-Z.f.B(a,c)};Z.f.xc=function(a,c){return a===c};Z.f.Td=function(a,c,d){d=Z.f.$a(a,c,d);return 0>d?(Z.f.sb(a,c,-(d+1)),!0):!1};Z.f.Ud=function(a,c,d){c=Z.f.$a(a,c,d);return 0<=c?Z.f.S(a,c):!1};
Z.f.Wd=function(a,c,d){for(var e={},f=0;f<a.length;f++){var g=a[f],h=c.call(d,g,f,a);Z.N(h)&&(e[h]||(e[h]=[])).push(g)}return e};Z.f.og=function(a,c,d){var e={};Z.f.forEach(a,function(f,g){e[c.call(d,f,g,a)]=f});return e};Z.f.Hf=function(a,c,d){var e=[],f=0,g=a;d=d||1;void 0!==c&&(f=a,g=c);if(0>d*(g-f))return[];if(0<d)for(a=f;a<g;a+=d)e.push(a);else for(a=f;a>g;a+=d)e.push(a);return e};Z.f.repeat=function(a,c){for(var d=[],e=0;e<c;e++)d[e]=a;return d};
Z.f.Bc=function(a){for(var c=[],d=0;d<arguments.length;d++){var e=arguments[d];if(Z.isArray(e))for(var f=0;f<e.length;f+=8192)for(var g=Z.f.Bc.apply(null,Z.f.slice(e,f,f+8192)),h=0;h<g.length;h++)c.push(g[h]);else c.push(e)}return c};Z.f.rotate=function(a,c){a.length&&(c%=a.length,0<c?Array.prototype.unshift.apply(a,a.splice(-c,c)):0>c&&Array.prototype.push.apply(a,a.splice(0,-c)));return a};Z.f.xf=function(a,c,d){c=Array.prototype.splice.call(a,c,1);Array.prototype.splice.call(a,d,0,c[0])};
Z.f.Cg=function(a){if(!arguments.length)return[];for(var c=[],d=arguments[0].length,e=1;e<arguments.length;e++)arguments[e].length<d&&(d=arguments[e].length);for(e=0;e<d;e++){for(var f=[],g=0;g<arguments.length;g++)f.push(arguments[g][e]);c.push(f)}return c};Z.f.$f=function(a,c){for(var d=c||Math.random,e=a.length-1;0<e;e--){var f=Math.floor(d()*(e+1)),g=a[e];a[e]=a[f];a[f]=g}};Z.f.pe=function(a,c){var d=[];Z.f.forEach(c,function(c){d.push(a[c])});return d};Z.locale={};
Z.locale.J={COUNTRY:{AD:"Andorra",AE:Ld,AF:Kd,AG:Qa,AI:"Anguilla",AL:"Shqip\u00ebria",AM:Jd,AN:wb,AO:"Angola",AQ:"Antarctica",AR:"Argentina",AS:Pa,AT:"\u00d6sterreich",AU:"Australia",AW:"Aruba",AX:"\u00c5land",AZ:"Az\u0259rbaycan",BA:y,BB:"Barbados",BD:"\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6",BE:"Belgi\u00eb",BF:"Burkina Faso",BG:"\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",BH:"\u0627\u0644\u0628\u062d\u0631\u064a\u0646",BI:"Burundi",BJ:"B\u00e9nin",BM:"Bermuda",BN:"Brunei",BO:x,BR:"Brasil",
BS:"Bahamas",BT:"\u092d\u0942\u091f\u093e\u0928",BV:"Bouvet Island",BW:Ta,BY:Gd,BZ:"Belize",CA:"Canada",CC:"Cocos (Keeling) Islands",CD:Ib,CF:Hb,CG:"Congo",CH:"Schweiz",CI:"C\u00f4te d\u2019Ivoire",CK:"Cook Islands",CL:"Chile",CM:"Cameroun",CN:"\u4e2d\u56fd",CO:"Colombia",CR:"Costa Rica",CS:Nb,CU:"Cuba",CV:"Cabo Verde",CX:Wa,CY:"\u039a\u03cd\u03c0\u03c1\u03bf\u03c2",CZ:Fd,DD:"East Germany",DE:"Deutschland",DJ:"Jabuuti",DK:"Danmark",DM:"Dominica",DO:Fb,DZ:"\u0627\u0644\u062c\u0632\u0627\u0626\u0631",
EC:"Ecuador",EE:"Eesti",EG:"\u0645\u0635\u0631",EH:Md,ER:"\u0627\u0631\u064a\u062a\u0631\u064a\u0627",ES:"Espa\u00f1a",ET:X,FI:"Suomi",FJ:"\u092b\u093f\u091c\u0940",FK:fb,FM:B,FO:"F\u00f8royar",FR:"France",FX:"Metropolitan France",GA:"Gabon",GB:$b,GD:"Grenada",GE:"\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd",GF:hb,GG:"Guernsey",GH:z,GI:"Gibraltar",GL:nb,GM:"Gambia",GN:"Guin\u00e9e",GP:"Guadeloupe",GQ:gb,GR:"\u0395\u03bb\u03bb\u03ac\u03b4\u03b1",GS:"South Georgia and the South Sandwich Islands",
GT:"Guatemala",GU:"Guam",GW:"Guin\u00e9 Bissau",GY:"Guyana",HK:"\u9999\u6e2f",HM:"Heard Island and McDonald Islands",HN:jb,HR:"Hrvatska",HT:"Ha\u00efti",HU:"Magyarorsz\u00e1g",ID:kb,IE:"Ireland",IL:"\u05d9\u05e9\u05e8\u05d0\u05dc",IM:"Isle of Man",IN:W,IO:"British Indian Ocean Territory",IQ:"\u0627\u0644\u0639\u0631\u0627\u0642",IR:"\u0627\u06cc\u0631\u0627\u0646",IS:"\u00cdsland",IT:"Italia",JE:"Jersey",JM:"Jamaica",JO:"\u0627\u0644\u0623\u0631\u062f\u0646",JP:"\u65e5\u672c",KE:"Kenya",KG:Hd,KH:"\u1780\u1798\u17d2\u1796\u17bb\u1787\u17b6",
KI:ob,KM:Pd,KN:Kb,KP:Rd,KR:"\ub300\ud55c\ubbfc\uad6d",KW:"\u0627\u0644\u0643\u0648\u064a\u062a",KY:Va,KZ:"\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",LA:"\u0e25\u0e32\u0e27",LB:"\u0644\u0628\u0646\u0627\u0646",LC:"Saint Lucia",LI:"Liechtenstein",LK:"\u0b87\u0bb2\u0b99\u0bcd\u0b95\u0bc8",LR:"Liberia",LS:"Lesotho",LT:"Lietuva",LU:sb,LV:"Latvija",LY:"\u0644\u064a\u0628\u064a\u0627",MA:"\u0627\u0644\u0645\u063a\u0631\u0628",MC:"Monaco",MD:vb,ME:"\u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430",
MG:tb,MH:ub,MK:"\u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430",ML:"\u0645\u0627\u0644\u064a",MM:"Myanmar",MN:"\u8499\u53e4",MO:"\u6fb3\u95e8",MP:zb,MQ:"Martinique",MR:"\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627",MS:"Montserrat",MT:"Malta",MU:"Mauritius",MV:"Maldives",MW:"Malawi",MX:"M\u00e9xico",MY:"Malaysia",MZ:"Mo\u00e7ambique",NA:"Namibia",NC:Ab,NE:"Niger",NF:yb,NG:C,NI:"Nicaragua",NL:"Nederland",NO:"Norge",NP:"\u0928\u0947\u092a\u093e\u0932",NR:"Nauru",NT:"Neutral Zone",
NU:"Niue",NZ:xb,OM:"\u0639\u0645\u0627\u0646",PA:"Panam\u00e1",PE:"Per\u00fa",PF:Db,PG:D,PH:Cb,PK:Qd,PL:"Polska",PM:Mb,PN:"Pitcairn",PR:Eb,PS:"\u0641\u0644\u0633\u0637\u064a\u0646",PT:"Portugal",PW:"Palau",PY:Bb,QA:"\u0642\u0637\u0631",QO:"Outlying Oceania",QU:"European Union",RE:"R\u00e9union",RO:"Rom\u00e2nia",RS:"\u0421\u0440\u0431\u0438\u0458\u0430",RU:"\u0420\u043e\u0441\u0441\u0438\u044f",RW:E,SA:Nd,SB:Qb,SC:Ob,SD:"\u0627\u0644\u0633\u0648\u062f\u0627\u0646",SE:"Sverige",SG:"\u65b0\u52a0\u5761",
SH:"Saint Helena",SI:"Slovenija",SJ:Tb,SK:Pb,SL:"Sierra Leone",SM:"San Marino",SN:G,SO:"Somali",SR:"Suriname",ST:Vb,SU:"Union of Soviet Socialist Republics",SV:"El Salvador",SY:"\u0633\u0648\u0631\u064a\u0627",SZ:Ub,TC:Yb,TD:"\u062a\u0634\u0627\u062f",TF:"French Southern Territories",TG:"Togo",TH:"\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e44\u0e17\u0e22",TJ:"\u062a\u0627\u062c\u06cc\u06a9\u0633\u062a\u0627\u0646",TK:H,TL:Xb,TM:"\u0422\u0443\u0440\u043a\u043c\u0435\u043d\u0438\u0441\u0442\u0430\u043d",
TN:"\u062a\u0648\u0646\u0633",TO:"Tonga",TR:J,TT:"Trinidad y Tobago",TV:I,TW:"\u53f0\u6e7e",TZ:Wb,UA:"\u0423\u043a\u0440\u0430\u0457\u043d\u0430",UG:"Uganda",UM:bc,US:ac,UY:"Uruguay",UZ:"\u040e\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u043e\u043d",VA:"Vaticano",VC:Lb,VE:"Venezuela",VG:Ua,VI:Zb,VN:"Vi\u1ec7t Nam",VU:K,WF:dc,WS:"Samoa",YD:"People's Democratic Republic of Yemen",YE:"\u0627\u0644\u064a\u0645\u0646",YT:"Mayotte",ZA:F,ZM:"Zambia",ZW:"Zimbabwe",ZZ:cc,aa_DJ:"Jabuuti",aa_ER:"\u00c9rythr\u00e9e",
aa_ER_SAAHO:"\u00c9rythr\u00e9e",aa_ET:lb,af_NA:"Namibi\u00eb",af_ZA:"Suid-Afrika",ak_GH:z,am_ET:X,ar_AE:Ld,ar_BH:"\u0627\u0644\u0628\u062d\u0631\u064a\u0646",ar_DJ:"\u062c\u064a\u0628\u0648\u062a\u064a",ar_DZ:"\u0627\u0644\u062c\u0632\u0627\u0626\u0631",ar_EG:"\u0645\u0635\u0631",ar_EH:Md,ar_ER:"\u0627\u0631\u064a\u062a\u0631\u064a\u0627",ar_IL:"\u0627\u0633\u0631\u0627\u0626\u064a\u0644",ar_IQ:"\u0627\u0644\u0639\u0631\u0627\u0642",ar_JO:"\u0627\u0644\u0623\u0631\u062f\u0646",ar_KM:Pd,ar_KW:"\u0627\u0644\u0643\u0648\u064a\u062a",
ar_LB:"\u0644\u0628\u0646\u0627\u0646",ar_LY:"\u0644\u064a\u0628\u064a\u0627",ar_MA:"\u0627\u0644\u0645\u063a\u0631\u0628",ar_MR:"\u0645\u0648\u0631\u064a\u062a\u0627\u0646\u064a\u0627",ar_OM:"\u0639\u0645\u0627\u0646",ar_PS:"\u0641\u0644\u0633\u0637\u064a\u0646",ar_QA:"\u0642\u0637\u0631",ar_SA:Nd,ar_SD:"\u0627\u0644\u0633\u0648\u062f\u0627\u0646",ar_SY:"\u0633\u0648\u0631\u064a\u0627",ar_TD:"\u062a\u0634\u0627\u062f",ar_TN:"\u062a\u0648\u0646\u0633",ar_YE:"\u0627\u0644\u064a\u0645\u0646",as_IN:"\u09ad\u09be\u09f0\u09a4",
ay_BO:x,az_AZ:"Az\u0259rbaycan",az_Cyrl_AZ:"\u0410\u0437\u04d9\u0440\u0431\u0430\u0458\u04b9\u0430\u043d",az_Latn_AZ:"Azerbaycan",be_BY:Gd,bg_BG:"\u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f",bi_VU:K,bn_BD:"\u09ac\u09be\u0982\u09b2\u09be\u09a6\u09c7\u09b6",bn_IN:"\u09ad\u09be\u09b0\u09a4",bo_CN:"\u0f62\u0f92\u0fb1\u0f0b\u0f53\u0f42",bo_IN:"\u0f62\u0f92\u0fb1\u0f0b\u0f42\u0f62\u0f0b",bs_BA:y,byn_ER:"\u12a4\u122d\u1275\u122b",ca_AD:"Andorra",ca_ES:"Espanya",cch_NG:C,ch_GU:"Guam",chk_FM:B,cop_Arab_EG:"\u0645\u0635\u0631",
cop_Arab_US:Od,cop_EG:"\u0645\u0635\u0631",cop_US:Od,cs_CZ:Fd,cy_GB:"Prydain Fawr",da_DK:"Danmark",da_GL:"Gr\u00f8nland",de_AT:"\u00d6sterreich",de_BE:"Belgien",de_CH:"Schweiz",de_DE:"Deutschland",de_LI:"Liechtenstein",de_LU:"Luxemburg",dv_MV:"Maldives",dz_BT:"Bhutan",ee_GH:z,ee_TG:"Togo",efi_NG:C,el_CY:"\u039a\u03cd\u03c0\u03c1\u03bf\u03c2",el_GR:"\u0395\u03bb\u03bb\u03ac\u03b4\u03b1",en_AG:Qa,en_AI:"Anguilla",en_AS:Pa,en_AU:"Australia",en_BB:"Barbados",en_BE:"Belgium",en_BM:"Bermuda",en_BS:"Bahamas",
en_BW:Ta,en_BZ:"Belize",en_CA:"Canada",en_CC:"Cocos Islands",en_CK:"Cook Islands",en_CM:"Cameroon",en_CX:Wa,en_DM:"Dominica",en_FJ:"Fiji",en_FK:fb,en_FM:B,en_GB:$b,en_GD:"Grenada",en_GG:"Guernsey",en_GH:z,en_GI:"Gibraltar",en_GM:"Gambia",en_GU:"Guam",en_GY:"Guyana",en_HK:"Hong Kong",en_HN:jb,en_IE:"Ireland",en_IM:"Isle of Man",en_IN:"India",en_JE:"Jersey",en_JM:"Jamaica",en_KE:"Kenya",en_KI:ob,en_KN:Kb,en_KY:Va,en_LC:"Saint Lucia",en_LR:"Liberia",en_LS:"Lesotho",en_MH:ub,en_MP:zb,en_MS:"Montserrat",
en_MT:"Malta",en_MU:"Mauritius",en_MW:"Malawi",en_NA:"Namibia",en_NF:yb,en_NG:C,en_NR:"Nauru",en_NU:"Niue",en_NZ:xb,en_PG:D,en_PH:Cb,en_PK:"Pakistan",en_PN:"Pitcairn",en_PR:Eb,en_RW:E,en_SB:Qb,en_SC:Ob,en_SG:"Singapore",en_SH:"Saint Helena",en_SL:"Sierra Leone",en_SZ:Ub,en_TC:Yb,en_TK:H,en_TO:"Tonga",en_TT:"Trinidad and Tobago",en_TV:I,en_TZ:Wb,en_UG:"Uganda",en_UM:bc,en_US:ac,en_US_POSIX:ac,en_VC:Lb,en_VG:Ua,en_VI:Zb,en_VU:K,en_WS:"Samoa",en_ZA:F,en_ZM:"Zambia",en_ZW:"Zimbabwe",es_AR:"Argentina",
es_BO:x,es_CL:"Chile",es_CO:"Colombia",es_CR:"Costa Rica",es_CU:"Cuba",es_DO:Fb,es_EC:"Ecuador",es_ES:"Espa\u00f1a",es_GQ:"Guinea Ecuatorial",es_GT:"Guatemala",es_HN:jb,es_MX:"M\u00e9xico",es_NI:"Nicaragua",es_PA:"Panam\u00e1",es_PE:"Per\u00fa",es_PH:"Filipinas",es_PR:Eb,es_PY:Bb,es_SV:"El Salvador",es_US:"Estados Unidos",es_UY:"Uruguay",es_VE:"Venezuela",et_EE:"Eesti",eu_ES:"Espainia",fa_AF:Kd,fa_IR:"\u0627\u06cc\u0631\u0627\u0646",fi_FI:"Suomi",fil_PH:Cb,fj_FJ:"Fiji",fo_FO:"F\u00f8royar",fr_BE:"Belgique",
fr_BF:"Burkina Faso",fr_BI:"Burundi",fr_BJ:"B\u00e9nin",fr_CA:"Canada",fr_CD:Ib,fr_CF:Hb,fr_CG:"Congo",fr_CH:"Suisse",fr_CI:"C\u00f4te d\u2019Ivoire",fr_CM:"Cameroun",fr_DJ:"Djibouti",fr_DZ:"Alg\u00e9rie",fr_FR:"France",fr_GA:"Gabon",fr_GF:hb,fr_GN:"Guin\u00e9e",fr_GP:"Guadeloupe",fr_GQ:gb,fr_HT:"Ha\u00efti",fr_KM:"Comores",fr_LU:sb,fr_MA:"Maroc",fr_MC:"Monaco",fr_MG:tb,fr_ML:"Mali",fr_MQ:"Martinique",fr_MU:"Maurice",fr_NC:Ab,fr_NE:"Niger",fr_PF:Db,fr_PM:Mb,fr_RE:"R\u00e9union",fr_RW:E,fr_SC:Ob,fr_SN:G,
fr_SY:"Syrie",fr_TD:"Tchad",fr_TG:"Togo",fr_TN:"Tunisie",fr_VU:K,fr_WF:dc,fr_YT:"Mayotte",fur_IT:"Italia",ga_IE:"\u00c9ire",gaa_GH:z,gez_ER:"\u12a4\u122d\u1275\u122b",gez_ET:X,gil_KI:ob,gl_ES:"Espa\u00f1a",gn_PY:Bb,gu_IN:"\u0aad\u0abe\u0ab0\u0aa4",gv_GB:Gb,ha_Arab_NG:"\u0646\u064a\u062c\u064a\u0631\u064a\u0627",ha_GH:"\u063a\u0627\u0646\u0627",ha_Latn_GH:z,ha_Latn_NE:"Niger",ha_Latn_NG:"Nig\u00e9ria",ha_NE:"\u0627\u0644\u0646\u064a\u062c\u0631",ha_NG:"\u0646\u064a\u062c\u064a\u0631\u064a\u0627",haw_US:"\u02bbAmelika Hui P\u016b \u02bbIa",
he_IL:"\u05d9\u05e9\u05e8\u05d0\u05dc",hi_IN:W,ho_PG:D,hr_BA:y,hr_HR:"Hrvatska",ht_HT:"Ha\u00efti",hu_HU:"Magyarorsz\u00e1g",hy_AM:Jd,hy_AM_REVISED:Jd,id_ID:kb,ig_NG:C,ii_CN:"\ua34f\ua1e9",is_IS:"\u00cdsland",it_CH:"Svizzera",it_IT:"Italia",it_SM:"San Marino",ja_JP:"\u65e5\u672c",ka_GE:"\u10e1\u10d0\u10e5\u10d0\u10e0\u10d7\u10d5\u10d4\u10da\u10dd",kaj_NG:C,kam_KE:"Kenya",kcg_NG:C,kfo_NG:"Nig\u00e9ria",kk_KZ:"\u049a\u0430\u0437\u0430\u049b\u0441\u0442\u0430\u043d",kl_GL:nb,km_KH:"\u1780\u1798\u17d2\u1796\u17bb\u1787\u17b6",
kn_IN:"\u0cad\u0cbe\u0cb0\u0ca4",ko_KP:Rd,ko_KR:"\ub300\ud55c\ubbfc\uad6d",kok_IN:W,kos_FM:B,kpe_GN:"Guin\u00e9e",kpe_LR:"Lib\u00e9ria",ks_IN:W,ku_IQ:"Irak",ku_IR:"\u0130ran",ku_Latn_IQ:"Irak",ku_Latn_IR:"\u0130ran",ku_Latn_SY:"Suriye",ku_Latn_TR:J,ku_SY:"Suriye",ku_TR:J,kw_GB:Gb,ky_Cyrl_KG:Hd,ky_KG:"K\u0131rg\u0131zistan",la_VA:"Vaticano",lb_LU:sb,ln_CD:Ib,ln_CG:"Kongo",lo_LA:"Laos",lt_LT:"Lietuva",lv_LV:"Latvija",mg_MG:tb,mh_MH:ub,mi_NZ:xb,mk_MK:"\u041c\u0430\u043a\u0435\u0434\u043e\u043d\u0438\u0458\u0430",
ml_IN:"\u0d07\u0d28\u0d4d\u0d24\u0d4d\u0d2f",mn_Cyrl_MN:"\u041c\u043e\u043d\u0433\u043e\u043b\u0438\u044f",mn_MN:"\u041c\u043e\u043d\u0433\u043e\u043b\u0438\u044f",mr_IN:W,ms_BN:"Brunei",ms_MY:"Malaysia",ms_SG:"Singapura",mt_MT:"Malta",my_MM:"Myanmar",na_NR:"Nauru",nb_NO:"Norge",nb_SJ:Tb,ne_NP:"\u0928\u0947\u092a\u093e\u0932",niu_NU:"Niue",nl_AN:wb,nl_AW:"Aruba",nl_BE:"Belgi\u00eb",nl_NL:"Nederland",nl_SR:"Suriname",nn_NO:"Noreg",nr_ZA:F,nso_ZA:F,ny_MW:"Malawi",om_ET:lb,om_KE:"Keeniyaa",or_IN:"\u0b2d\u0b3e\u0b30\u0b24",
pa_Arab_PK:Qd,pa_Guru_IN:"\u0a2d\u0a3e\u0a30\u0a24",pa_IN:"\u0a2d\u0a3e\u0a30\u0a24",pa_PK:Qd,pap_AN:wb,pau_PW:"Palau",pl_PL:"Polska",pon_FM:B,ps_AF:Kd,pt_AO:"Angola",pt_BR:"Brasil",pt_CV:"Cabo Verde",pt_GW:"Guin\u00e9 Bissau",pt_MZ:"Mo\u00e7ambique",pt_PT:"Portugal",pt_ST:Vb,pt_TL:Xb,qu_BO:x,qu_PE:"Per\u00fa",rm_CH:"Schweiz",rn_BI:"Burundi",ro_MD:vb,ro_RO:"Rom\u00e2nia",ru_BY:Gd,ru_KG:Hd,ru_KZ:"\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d",ru_RU:"\u0420\u043e\u0441\u0441\u0438\u044f",ru_UA:"\u0423\u043a\u0440\u0430\u0438\u043d\u0430",
rw_RW:E,sa_IN:W,sd_Deva_IN:W,sd_IN:W,se_FI:"Finland",se_NO:"Norge",sg_CF:Hb,sh_BA:"Bosnia and Herzegovina",sh_CS:Nb,si_LK:"Sri Lanka",sid_ET:lb,sk_SK:Pb,sl_SI:"Slovenija",sm_AS:Pa,sm_WS:"Samoa",so_DJ:"Jabuuti",so_ET:"Itoobiya",so_KE:"Kiiniya",so_SO:"Soomaaliya",sq_AL:"Shqip\u00ebria",sr_BA:"\u0411\u043e\u0441\u043d\u0430 \u0438 \u0425\u0435\u0440\u0446\u0435\u0433\u043e\u0432\u0438\u043d\u0430",sr_CS:"\u0421\u0440\u0431\u0438\u0458\u0430 \u0438 \u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430",sr_Cyrl_BA:"\u0411\u043e\u0441\u043d\u0438\u044f",
sr_Cyrl_CS:"\u0421\u0435\u0440\u0431\u0438\u044f \u0438 \u0427\u0435\u0440\u043d\u043e\u0433\u043e\u0440\u0438\u044f",sr_Cyrl_ME:"\u0427\u0435\u0440\u043d\u043e\u0433\u043e\u0440\u0438\u044f",sr_Cyrl_RS:"\u0421\u0435\u0440\u0431\u0438\u044f",sr_Latn_BA:y,sr_Latn_CS:"Srbija i Crna Gora",sr_Latn_ME:"Crna Gora",sr_Latn_RS:"Srbija",sr_ME:"\u0426\u0440\u043d\u0430 \u0413\u043e\u0440\u0430",sr_RS:"\u0421\u0440\u0431\u0438\u0458\u0430",ss_SZ:Ub,ss_ZA:F,st_LS:"Lesotho",st_ZA:F,su_ID:kb,sv_AX:"\u00c5land",
sv_FI:"Finland",sv_SE:"Sverige",sw_KE:"Kenya",sw_TZ:Wb,sw_UG:"Uganda",swb_KM:Pd,syr_SY:"Syria",ta_IN:"\u0b87\u0ba8\u0bcd\u0ba4\u0bbf\u0baf\u0bbe",ta_LK:"\u0b87\u0bb2\u0b99\u0bcd\u0b95\u0bc8",ta_SG:"\u0b9a\u0bbf\u0b99\u0bcd\u0b95\u0baa\u0bcd\u0baa\u0bc2\u0bb0\u0bcd",te_IN:"\u0c2d\u0c3e\u0c30\u0c24 \u0c26\u0c47\u0c33\u0c02",tet_TL:Xb,tg_Cyrl_TJ:"\u0422\u0430\u0434\u0436\u0438\u043a\u0438\u0441\u0442\u0430\u043d",tg_TJ:"\u062a\u0627\u062c\u06a9\u0633\u062a\u0627\u0646",th_TH:"\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e44\u0e17\u0e22",
ti_ER:"\u12a4\u122d\u1275\u122b",ti_ET:X,tig_ER:"\u12a4\u122d\u1275\u122b",tk_TM:"\u062a\u0631\u06a9\u0645\u0646\u0633\u062a\u0627\u0646",tkl_TK:H,tn_BW:Ta,tn_ZA:F,to_TO:"Tonga",tpi_PG:D,tr_CY:"G\u00fcney K\u0131br\u0131s Rum Kesimi",tr_TR:J,ts_ZA:F,tt_RU:"\u0420\u043e\u0441\u0441\u0438\u044f",tvl_TV:I,ty_PF:Db,uk_UA:"\u0423\u043a\u0440\u0430\u0457\u043d\u0430",uli_FM:B,und_ZZ:cc,ur_IN:"\u0628\u06be\u0627\u0631\u062a",ur_PK:Qd,uz_AF:"Afganistan",uz_Arab_AF:Kd,uz_Cyrl_UZ:"\u0423\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u0430\u043d",
uz_Latn_UZ:"O\u02bfzbekiston",uz_UZ:"\u040e\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u043e\u043d",ve_ZA:F,vi_VN:"Vi\u1ec7t Nam",wal_ET:X,wo_Arab_SN:"\u0627\u0644\u0633\u0646\u063a\u0627\u0644",wo_Latn_SN:G,wo_SN:G,xh_ZA:F,yap_FM:B,yo_NG:C,zh_CN:"\u4e2d\u56fd",zh_HK:"\u9999\u6e2f",zh_Hans_CN:"\u4e2d\u56fd",zh_Hans_SG:"\u65b0\u52a0\u5761",zh_Hant_HK:"\u4e2d\u83ef\u4eba\u6c11\u5171\u548c\u570b\u9999\u6e2f\u7279\u5225\u884c\u653f\u5340",zh_Hant_MO:"\u6fb3\u9580",zh_Hant_TW:"\u81fa\u7063",zh_MO:"\u6fb3\u95e8",
zh_SG:"\u65b0\u52a0\u5761",zh_TW:"\u53f0\u6e7e",zu_ZA:F},LANGUAGE:{aa:"afar",ab:"\u0430\u0431\u0445\u0430\u0437\u0441\u043a\u0438\u0439",ace:"Aceh",ach:"Acoli",ada:"Adangme",ady:"\u0430\u0434\u044b\u0433\u0435\u0439\u0441\u043a\u0438\u0439",ae:"Avestan",af:"Afrikaans",afa:"Afro-Asiatic Language",afh:"Afrihili",ain:"Ainu",ak:"Akan",akk:"Akkadian",ale:"Aleut",alg:"Algonquian Language",alt:"Southern Altai",am:"\u12a0\u121b\u122d\u129b",an:"Aragonese",ang:"Old English",anp:"Angika",apa:"Apache Language",
ar:"\u0627\u0644\u0639\u0631\u0628\u064a\u0629",arc:"Aramaic",arn:"Araucanian",arp:"Arapaho",art:"Artificial Language",arw:"Arawak",as:"\u0985\u09b8\u09ae\u09c0\u09af\u09bc\u09be",ast:"asturiano",ath:"Athapascan Language",aus:"Australian Language",av:"\u0430\u0432\u0430\u0440\u0441\u043a\u0438\u0439",awa:"Awadhi",ay:"aimara",az:"az\u0259rbaycanca",az_Arab:"\u062a\u0631\u06a9\u06cc \u0622\u0630\u0631\u0628\u0627\u06cc\u062c\u0627\u0646\u06cc",az_Cyrl:"\u0410\u0437\u04d9\u0440\u0431\u0430\u0458\u04b9\u0430\u043d",
az_Latn:"Azerice",ba:"\u0431\u0430\u0448\u043a\u0438\u0440\u0441\u043a\u0438\u0439",bad:"Banda",bai:"Bamileke Language",bal:"\u0628\u0644\u0648\u0686\u06cc",ban:"Balin",bas:"Basa",bat:"Baltic Language",be:"\u0431\u0435\u043b\u0430\u0440\u0443\u0441\u043a\u0430\u044f",bej:"Beja",bem:"Bemba",ber:"Berber",bg:"\u0431\u044a\u043b\u0433\u0430\u0440\u0441\u043a\u0438",bh:"\u092c\u093f\u0939\u093e\u0930\u0940",bho:"Bhojpuri",bi:"bichelamar ; bislama",bik:"Bikol",bin:"Bini",bla:"Siksika",bm:"bambara",bn:"\u09ac\u09be\u0982\u09b2\u09be",
bnt:"Bantu",bo:"\u0f54\u0f7c\u0f51\u0f0b\u0f66\u0f90\u0f51\u0f0b",br:"breton",bra:"Braj",bs:"Bosanski",btk:"Batak",bua:"Buriat",bug:"Bugis",byn:"\u1265\u120a\u1295",ca:"catal\u00e0",cad:"Caddo",cai:"Central American Indian Language",car:"Carib",cau:"Caucasian Language",cch:"Atsam",ce:"\u0447\u0435\u0447\u0435\u043d\u0441\u043a\u0438\u0439",ceb:"Cebuano",cel:"Celtic Language",ch:"Chamorro",chb:"Chibcha",chg:"Chagatai",chk:"Chuukese",chm:"\u043c\u0430\u0440\u0438\u0439\u0441\u043a\u0438\u0439 (\u0447\u0435\u0440\u0435\u043c\u0438\u0441\u0441\u043a\u0438\u0439)",
chn:"Chinook Jargon",cho:"Choctaw",chp:"Chipewyan",chr:"Cherokee",chy:"Cheyenne",cmc:"Chamic Language",co:"corse",cop:"\u0642\u0628\u0637\u064a\u0629",cop_Arab:"\u0642\u0628\u0637\u064a\u0629",cpe:"English-based Creole or Pidgin",cpf:"French-based Creole or Pidgin",cpp:"Portuguese-based Creole or Pidgin",cr:"Cree",crh:"Crimean Turkish",crp:"Creole or Pidgin",cs:"\u010de\u0161tina",csb:"Kashubian",cu:"Church Slavic",cus:"Cushitic Language",cv:"\u0447\u0443\u0432\u0430\u0448\u0441\u043a\u0438\u0439",
cy:"Cymraeg",da:"dansk",dak:"Dakota",dar:"\u0434\u0430\u0440\u0433\u0432\u0430",day:"Dayak",de:"Deutsch",del:"Delaware",den:"Slave",dgr:"Dogrib",din:"Dinka",doi:"\u0627\u0644\u062f\u0648\u062c\u0631\u0649",dra:"Dravidian Language",dsb:"Lower Sorbian",dua:"Duala",dum:"Middle Dutch",dv:"Divehi",dyu:"dioula",dz:"\u0f62\u0fab\u0f7c\u0f44\u0f0b\u0f41",ee:"Ewe",efi:"Efik",egy:"Ancient Egyptian",eka:"Ekajuk",el:"\u0395\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ac",elx:"Elamite",en:"English",enm:"Middle English",
eo:"esperanto",es:"espa\u00f1ol",et:"eesti",eu:"euskara",ewo:"Ewondo",fa:"\u0641\u0627\u0631\u0633\u06cc",fan:"fang",fat:"Fanti",ff:"Fulah",fi:"suomi",fil:"Filipino",fiu:"Finno-Ugrian Language",fj:"Fijian",fo:"f\u00f8royskt",fon:"Fon",fr:"fran\u00e7ais",frm:"Middle French",fro:"Old French",frr:"Northern Frisian",frs:"Eastern Frisian",fur:"friulano",fy:"Fries",ga:"Gaeilge",gaa:"Ga",gay:"Gayo",gba:"Gbaya",gd:"Scottish Gaelic",gem:"Germanic Language",gez:"\u130d\u12d5\u12dd\u129b",gil:"Gilbertese",gl:"galego",
gmh:"Middle High German",gn:"guaran\u00ed",goh:"Old High German",gon:"Gondi",gor:"Gorontalo",got:"Gothic",grb:"Grebo",grc:"\u0391\u03c1\u03c7\u03b1\u03af\u03b1 \u0395\u03bb\u03bb\u03b7\u03bd\u03b9\u03ba\u03ac",gsw:"Schweizerdeutsch",gu:"\u0a97\u0ac1\u0a9c\u0ab0\u0abe\u0aa4\u0ac0",gv:"Gaelg",gwi:"Gwich\u02bcin",ha:"\u0627\u0644\u0647\u0648\u0633\u0627",ha_Arab:"\u0627\u0644\u0647\u0648\u0633\u0627",ha_Latn:"haoussa",hai:"Haida",haw:"\u02bb\u014dlelo Hawai\u02bbi",he:"\u05e2\u05d1\u05e8\u05d9\u05ea",
hi:"\u0939\u093f\u0902\u0926\u0940",hil:"Hiligaynon",him:"Himachali",hit:"Hittite",hmn:"Hmong",ho:"Hiri Motu",hr:"hrvatski",hsb:"Upper Sorbian",ht:"ha\u00eftien",hu:"magyar",hup:"Hupa",hy:"\u0540\u0561\u0575\u0565\u0580\u0567\u0576",hz:"Herero",ia:"interlingvao",iba:"Iban",id:"Bahasa Indonesia",ie:"Interlingue",ig:"Igbo",ii:"\ua188\ua320\ua259",ijo:"Ijo",ik:"Inupiaq",ilo:"Iloko",inc:"Indic Language",ine:"Indo-European Language",inh:"\u0438\u043d\u0433\u0443\u0448\u0441\u043a\u0438\u0439",io:"Ido",
ira:"Iranian Language",iro:"Iroquoian Language",is:"\u00edslenska",it:"italiano",iu:"Inuktitut",ja:"\u65e5\u672c\u8a9e",jbo:"Lojban",jpr:"Judeo-Persian",jrb:"Judeo-Arabic",jv:"Jawa",ka:"\u10e5\u10d0\u10e0\u10d7\u10e3\u10da\u10d8",kaa:"\u043a\u0430\u0440\u0430\u043a\u0430\u043b\u043f\u0430\u043a\u0441\u043a\u0438\u0439",kab:"kabyle",kac:"Kachin",kaj:"Jju",kam:"Kamba",kar:"Karen",kaw:"Kawi",kbd:"\u043a\u0430\u0431\u0430\u0440\u0434\u0438\u043d\u0441\u043a\u0438\u0439",kcg:"Tyap",kfo:"koro",kg:"Kongo",
kha:"Khasi",khi:"Khoisan Language",kho:"Khotanese",ki:"Kikuyu",kj:"Kuanyama",kk:"\u049a\u0430\u0437\u0430\u049b",kl:"kalaallisut",km:"\u1797\u17b6\u179f\u17b6\u1781\u17d2\u1798\u17c2\u179a",kmb:"quimbundo",kn:"\u0c95\u0ca8\u0ccd\u0ca8\u0ca1",ko:"\ud55c\uad6d\uc5b4",kok:"\u0915\u094b\u0902\u0915\u0923\u0940",kos:"Kosraean",kpe:"kpell\u00e9",kr:"Kanuri",krc:"\u043a\u0430\u0440\u0430\u0447\u0430\u0435\u0432\u043e-\u0431\u0430\u043b\u043a\u0430\u0440\u0441\u043a\u0438\u0439",krl:"\u043a\u0430\u0440\u0435\u043b\u044c\u0441\u043a\u0438\u0439",
kro:"Kru",kru:"Kurukh",ks:"\u0915\u093e\u0936\u094d\u092e\u093f\u0930\u0940",ku:"K\u00fcrt\u00e7e",ku_Arab:"\u0627\u0644\u0643\u0631\u062f\u064a\u0629",ku_Latn:"K\u00fcrt\u00e7e",kum:"\u043a\u0443\u043c\u044b\u043a\u0441\u043a\u0438\u0439",kut:"Kutenai",kv:"Komi",kw:"kernewek",ky:"K\u0131rg\u0131zca",ky_Arab:"\u0627\u0644\u0642\u064a\u0631\u063a\u0633\u062a\u0627\u0646\u064a\u0629",ky_Cyrl:"\u043a\u0438\u0440\u0433\u0438\u0437\u0441\u043a\u0438\u0439",la:"latino",lad:"\u05dc\u05d3\u05d9\u05e0\u05d5",
lah:"\u0644\u0627\u0647\u0646\u062f\u0627",lam:"Lamba",lb:"luxembourgeois",lez:"\u043b\u0435\u0437\u0433\u0438\u043d\u0441\u043a\u0438\u0439",lg:"Ganda",li:"Limburgs",ln:"lingala",lo:"Lao",lol:"mongo",loz:"Lozi",lt:"lietuvi\u0173",lu:"luba-katanga",lua:"luba-lulua",lui:"Luiseno",lun:"Lunda",luo:"Luo",lus:"Lushai",lv:"latvie\u0161u",mad:"Madura",mag:"Magahi",mai:"Maithili",mak:"Makassar",man:"Mandingo",map:"Austronesian",mas:"Masai",mdf:"\u043c\u043e\u043a\u0448\u0430",mdr:"Mandar",men:"Mende",mg:"malgache",
mga:"Middle Irish",mh:"Marshallese",mi:"Maori",mic:"Micmac",min:"Minangkabau",mis:"Miscellaneous Language",mk:"\u043c\u0430\u043a\u0435\u0434\u043e\u043d\u0441\u043a\u0438",mkh:"Mon-Khmer Language",ml:"\u0d2e\u0d32\u0d2f\u0d3e\u0d33\u0d02",mn:Id,mn_Cyrl:Id,mn_Mong:Id,mnc:"Manchu",mni:"Manipuri",mno:"Manobo Language",mo:"Moldavian",moh:"Mohawk",mos:"mor\u00e9 ; mossi",mr:"\u092e\u0930\u093e\u0920\u0940",ms:"Bahasa Melayu",mt:"Malti",mul:"Multiple Languages",mun:"Munda Language",mus:"Creek",mwl:"Mirandese",
mwr:"Marwari",my:"Burmese",myn:"Mayan Language",myv:"\u044d\u0440\u0437\u044f",na:"Nauru",nah:"Nahuatl",nai:"North American Indian Language",nap:"napoletano",nb:"norsk bokm\u00e5l",nd:"North Ndebele",nds:"Low German",ne:"\u0928\u0947\u092a\u093e\u0932\u0940","new":"Newari",ng:"Ndonga",nia:"Nias",nic:"Niger-Kordofanian Language",niu:"Niuean",nl:"Nederlands",nn:"nynorsk",no:"Norwegian",nog:"\u043d\u043e\u0433\u0430\u0439\u0441\u043a\u0438\u0439",non:"Old Norse",nqo:"N\u2019Ko",nr:"South Ndebele",nso:"Northern Sotho",
nub:"Nubian Language",nv:"Navajo",nwc:"Classical Newari",ny:"nianja; chicheua; cheua",nym:"Nyamwezi",nyn:"Nyankole",nyo:"Nyoro",nzi:"Nzima",oc:"occitan",oj:"Ojibwa",om:"Oromoo",or:"\u0b13\u0b21\u0b3c\u0b3f\u0b06",os:"\u043e\u0441\u0435\u0442\u0438\u043d\u0441\u043a\u0438\u0439",osa:"Osage",ota:"Ottoman Turkish",oto:"Otomian Language",pa:"\u0a2a\u0a70\u0a1c\u0a3e\u0a2c\u0a40",pa_Arab:"\u067e\u0646\u062c\u0627\u0628",pa_Guru:"\u0a2a\u0a70\u0a1c\u0a3e\u0a2c\u0a40",paa:"Papuan Language",pag:"Pangasinan",
pal:"Pahlavi",pam:"Pampanga",pap:"Papiamento",pau:"Palauan",peo:"Old Persian",phi:"Philippine Language",phn:"Phoenician",pi:"\u0e1a\u0e32\u0e25\u0e35",pl:"polski",pon:"Pohnpeian",pra:"Prakrit Language",pro:"Old Proven\u00e7al",ps:"\u067e\u069a\u062a\u0648",pt:"portugu\u00eas",qu:"quechua",raj:"Rajasthani",rap:"Rapanui",rar:"Rarotongan",rm:"R\u00e4toromanisch",rn:"roundi",ro:"rom\u00e2n\u0103",roa:"Romance Language",rom:"Romany",ru:"\u0440\u0443\u0441\u0441\u043a\u0438\u0439",rup:"Aromanian",rw:"rwanda",
sa:"\u0938\u0902\u0938\u094d\u0915\u0943\u0924 \u092d\u093e\u0937\u093e",sad:"Sandawe",sah:"\u044f\u043a\u0443\u0442\u0441\u043a\u0438\u0439",sai:"South American Indian Language",sal:"Salishan Language",sam:"\u05d0\u05e8\u05de\u05d9\u05ea \u05e9\u05d5\u05de\u05e8\u05d5\u05e0\u05d9\u05ea",sas:"Sasak",sat:"Santali",sc:"Sardinian",scn:"siciliano",sco:"Scots",sd:"\u0938\u093f\u0928\u094d\u0927\u0940",sd_Arab:"\u0633\u0646\u062f\u06cc",sd_Deva:"\u0938\u093f\u0928\u094d\u0927\u0940",se:"nordsamiska",sel:"\u0441\u0435\u043b\u044c\u043a\u0443\u043f\u0441\u043a\u0438\u0439",
sem:"Semitic Language",sg:"sangho",sga:"Old Irish",sgn:"Sign Language",sh:"Serbo-Croatian",shn:"Shan",si:"Sinhalese",sid:"Sidamo",sio:"Siouan Language",sit:"Sino-Tibetan Language",sk:"slovensk\u00fd",sl:"sloven\u0161\u010dina",sla:"Slavic Language",sm:"Samoan",sma:"sydsamiska",smi:"Sami Language",smj:"lulesamiska",smn:"Inari Sami",sms:"Skolt Sami",sn:"Shona",snk:"sonink\u00e9",so:"Soomaali",sog:"Sogdien",son:"Songhai",sq:"shqipe",sr:"\u0421\u0440\u043f\u0441\u043a\u0438",sr_Cyrl:"\u0441\u0435\u0440\u0431\u0441\u043a\u0438\u0439",
sr_Latn:"Srpski",srn:"Sranantongo",srr:"s\u00e9r\u00e8re",ss:"Swati",ssa:"Nilo-Saharan Language",st:"Sesotho",su:"Sundan",suk:"Sukuma",sus:"soussou",sux:"Sumerian",sv:"svenska",sw:"Kiswahili",syc:"Classical Syriac",syr:"Syriac",ta:"\u0ba4\u0bae\u0bbf\u0bb4\u0bcd",tai:"Tai Language",te:"\u0c24\u0c46\u0c32\u0c41\u0c17\u0c41",tem:"Timne",ter:"Tereno",tet:"t\u00e9tum",tg:"\u062a\u0627\u062c\u06a9",tg_Arab:"\u062a\u0627\u062c\u06a9",tg_Cyrl:"\u0442\u0430\u0434\u0436\u0438\u043a\u0441\u043a\u0438\u0439",
th:"\u0e44\u0e17\u0e22",ti:"\u1275\u130d\u122d\u129b",tig:"\u1275\u130d\u1228",tiv:"Tiv",tk:"\u062a\u0631\u06a9\u0645\u0646\u06cc",tkl:H,tl:"Tagalog",tlh:"Klingon",tli:"Tlingit",tmh:"tamacheq",tn:"Tswana",to:"Tonga",tog:"Nyasa Tonga",tpi:"Tok Pisin",tr:"T\u00fcrk\u00e7e",ts:"Tsonga",tsi:"Tsimshian",tt:"\u0442\u0430\u0442\u0430\u0440\u0441\u043a\u0438\u0439",tum:"Tumbuka",tup:"Tupi Language",tut:"\u0430\u043b\u0442\u0430\u0439\u0441\u043a\u0438\u0435 (\u0434\u0440\u0443\u0433\u0438\u0435)",tvl:I,tw:"Twi",
ty:"tahitien",tyv:"\u0442\u0443\u0432\u0438\u043d\u0441\u043a\u0438\u0439",udm:"\u0443\u0434\u043c\u0443\u0440\u0442\u0441\u043a\u0438\u0439",ug:"\u0443\u0439\u0433\u0443\u0440\u0441\u043a\u0438\u0439",uga:"Ugaritic",uk:"\u0443\u043a\u0440\u0430\u0457\u043d\u0441\u044c\u043a\u0430",umb:"umbundu",und:"English",ur:"\u0627\u0631\u062f\u0648",uz:"\u040e\u0437\u0431\u0435\u043a",uz_Arab:"\u0627\u06c9\u0632\u0628\u06d0\u06a9",uz_Cyrl:"\u0443\u0437\u0431\u0435\u043a\u0441\u043a\u0438\u0439",uz_Latn:"o'zbekcha",
vai:"Vai",ve:"Venda",vi:"Ti\u1ebfng Vi\u1ec7t",vo:"volapuko",vot:"Votic",wa:"Wallonisch",wak:"Wakashan Language",wal:"Walamo",war:"Waray",was:"Washo",wen:"Sorbian Language",wo:"wolof",wo_Arab:"\u0627\u0644\u0648\u0644\u0648\u0641",wo_Latn:"wolof",xal:"\u043a\u0430\u043b\u043c\u044b\u0446\u043a\u0438\u0439",xh:"Xhosa",yao:"iao",yap:"Yapese",yi:"\u05d9\u05d9\u05d3\u05d9\u05e9",yo:"Yoruba",ypk:"Yupik Language",za:"Zhuang",zap:"Zapotec",zen:"Zenaga",zh:"\u4e2d\u6587",zh_Hans:"\u4e2d\u6587",zh_Hant:"\u4e2d\u6587",
znd:"Zande",zu:"Zulu",zun:"Zuni",zxx:"No linguistic content",zza:"Zaza"}};Z.locale.Xf=function(a){a=a.replace(/-/g,L);Z.locale.L=a};Z.locale.Ea=function(){Z.locale.L||(Z.locale.L=xc);return Z.locale.L};Z.locale.I={vd:"DateTimeConstants",Ed:"NumberFormatConstants",fc:"TimeZoneConstants",Vb:rb,gc:"TimeZoneSelectedIds",ic:"TimeZoneSelectedShortNames",hc:"TimeZoneSelectedLongNames",ec:"TimeZoneAllLongNames"};Z.locale.Da=function(a){return(a=a.match(/^\w{2,3}([-_]|$)/))?a[0].replace(/[_-]/g,b):b};
Z.locale.jb=function(a){return(a=a.match(/[-_]([a-zA-Z]{2}|\d{3})([-_]|$)/))?a[0].replace(/[_-]/g,b):b};Z.locale.Pe=function(a){a=a.split(/[-_]/g);return 1<a.length&&a[1].match(/^[a-zA-Z]{4}$/)?a[1]:b};Z.locale.Qe=function(a){return(a=a.match(/[-_]([a-z]{2,})/))?a[1]:b};Z.locale.Le=function(a){var c=Z.locale.Da(a)+L+Z.locale.jb(a);return c in Z.locale.J.COUNTRY?Z.locale.J.COUNTRY[c]:a};Z.locale.He=function(a,c){c||(c=Z.locale.kb());var d=Z.locale.jb(a);return d in c.COUNTRY?c.COUNTRY[d]:a};
Z.locale.Me=function(a){if(a in Z.locale.J.LANGUAGE)return Z.locale.J.LANGUAGE[a];var c=Z.locale.Da(a);return c in Z.locale.J.LANGUAGE?Z.locale.J.LANGUAGE[c]:a};Z.locale.Ie=function(a,c){c||(c=Z.locale.kb());if(a in c.LANGUAGE)return c.LANGUAGE[a];var d=Z.locale.Da(a);return d in c.LANGUAGE?c.LANGUAGE[d]:a};Z.locale.K=function(a,c,d){Z.locale.l[c]||(Z.locale.l[c]={});Z.locale.l[c][d]=a;Z.locale.L||(Z.locale.L=d)};Z.locale.jf=function(a,c){return a in Z.locale.l&&c in Z.locale.l[a]};Z.locale.l={};
Z.locale.Kf=function(a,c){Z.locale.K(a,Z.locale.I.fc,c)};Z.locale.If=function(a,c){Z.locale.K(a,Z.locale.I.Vb,c)};Z.locale.Lf=function(a,c){Z.locale.K(a,Z.locale.I.gc,c)};Z.locale.Nf=function(a,c){Z.locale.K(a,Z.locale.I.ic,c)};Z.locale.Mf=function(a,c){Z.locale.K(a,Z.locale.I.hc,c)};Z.locale.Jf=function(a,c){Z.locale.K(a,Z.locale.I.ec,c)};Z.locale.kb=function(){var a=Z.locale.Ea(),a=a?a:Z.locale.Ea();return rb in Z.locale.l?Z.locale.l.LocaleNameConstants[a]:void 0};
Z.locale.Oe=function(a,c){var d=c?c:Z.locale.Ea();if(a in Z.locale.l){if(d in Z.locale.l[a])return Z.locale.l[a][d];d=d.split(L);return 1<d.length&&d[0]in Z.locale.l[a]?Z.locale.l[a][d[0]]:Z.locale.l[a].en}};var google={a:{}};google.a.b={};
google.a.b.languages={af:!0,am:!0,az:!0,ar:!0,arb:"ar",bg:!0,bn:!0,ca:!0,cs:!0,cmn:"zh",da:!0,de:!0,el:!0,en:!0,en_gb:!0,es:!0,es_419:!0,et:!0,eu:!0,fa:!0,fi:!0,fil:!0,fr:!0,fr_ca:!0,gl:!0,ka:!0,gu:!0,he:"iw",hi:!0,hr:!0,hu:!0,hy:!0,id:!0,"in":Nc,is:!0,it:!0,iw:!0,ja:!0,ji:"yi",jv:!1,jw:"jv",km:!0,kn:!0,ko:!0,lo:!0,lt:!0,lv:!0,ml:!0,mn:!0,mo:"ro",mr:!0,ms:!0,nb:"no",ne:!0,nl:!0,no:!0,pl:!0,pt:"pt_br",pt_br:!0,pt_pt:!0,ro:!0,ru:!0,si:!0,sk:!0,sl:!0,sr:!0,sv:!0,sw:!0,swh:"sw",ta:!0,te:!0,th:!0,tl:"fil",
tr:!0,uk:!0,ur:!0,vi:!0,yi:!1,zh:"zh_ch",zh_cn:!0,zh_hk:!0,zh_tw:!0,zsm:"ms",zu:!0};google.a.b.O={};google.a.b.P=nd;google.a.b.log=Y();google.a.b.error=Y();google.a.b.Y=!1;google.a.b.M=window;google.a.b.Fb={current:"44",upcoming:"44",41:U,42:U,43:U,44:U};google.a.b.Ta={gstatic:{prefix:"https://www.gstatic.com/charts",debug:"{prefix}/debug/{version}/jsapi_debug_{package}_module.js",compiled:"{prefix}/{version}/js/jsapi_compiled_{package}_module.js",i18n:"{prefix}/{version}/i18n/jsapi_compiled_i18n_{package}_module__{language}.js",css:yd,css_debug:yd,third_party:zd,third_party_gen:zd}};
google.a.b.o=google.a.b.Ta.gstatic;
google.a.b.yc={"default":[],format:[],ui:["format","default"],ui_base:["format","default"],annotatedtimeline:[V],annotationchart:[V,"controls",P,"table"],areachart:[V,N],bar:[V,Q,sd],barchart:[V,N],browserchart:[V],calendar:[V],charteditor:[V,P,S,oc,"gauge","motionchart","orgchart","table"],charteditor_base:[md,P,S,oc,"gauge","motionchart","orgchart","table_base"],columnchart:[V,N],controls:[V],controls_base:[md],corechart:[V],gantt:[V,Q],gauge:[V],geochart:[V],geomap:[V],geomap_base:[md],helloworld:[V],
imageareachart:[V,S],imagebarchart:[V,S],imagelinechart:[V,S],imagechart:[V],imagepiechart:[V,S],imagesparkline:[V,S],intensitymap:[V],line:[V,Q,sd],linechart:[V,N],map:[V],motionchart:[V],orgchart:[V],overtimecharts:[V,P],piechart:[V,N],sankey:["d3","d3.sankey",V],scatter:[V,Q,sd],scatterchart:[V,N],table:[V],table_base:[md],timeline:[V,Q],treemap:[V],wordtree:[V]};google.a.b.fd={d3:"d3/d3.js","d3.sankey":"d3/d3.sankey.js",webfontloader:"webfontloader/webfont.js"};google.a.b.Cb={dygraph:"dygraphs/dygraph-tickers-combined.js"};
google.a.b.vc={annotatedtimeline:"/annotatedtimeline/annotatedtimeline.css",annotationchart:"annotationchart/annotationchart.css",charteditor:"charteditor/charteditor.css",charteditor_base:"charteditor/charteditor_base.css",controls:"controls/controls.css",imagesparkline:"imagesparkline/imagesparkline.css",intensitymap:"intensitymap/intensitymap.css",orgchart:"orgchart/orgchart.css",table:"table/table.css",table_base:"table/table_base.css",ui:["util/util.css","core/tooltip.css"],ui_base:"util/util_base.css"};
google.a.b.ua=function(a,c){for(var d=c||{},e=[],f=0;f<a.length;f++){var g=a[f];if(!d[g]){d[g]=!0;var h=google.a.b.yc[g]||[];0<h.length&&(e=e.concat(google.a.b.ua(h,d)));e.push(g)}}return e};google.a.b.Dc=function(a){for(var c={},d=[],e=0;e<a.length;e++){var f=google.a.b.vc[a[e]];Z.isArray(f)||(f=[f]);for(var g=0;g<f.length;g++){var h=f[g];h&&!c[h]&&(c[h]=!0,d.push(h))}}return d};
google.a.b.Yf=function(a,c){if(c)if("undefined"===typeof a.onload){var d=!1;a.onreadystatechange=function(){d||(a.readyState&&a.readyState!==O?google.a.b.M.setTimeout(a.onreadystatechange,0):(d=!0,delete a.onreadystatechange,google.a.b.M.setTimeout(c,0)))}}else a.onload=c};
google.a.b.Tc=function(a,c){google.a.b.log(Uc+a);var d=c.createElement(fd);d.type=kd;d.language=Pc;d.async=!1;d.defer=!1;var e=c.body||c.head||c.getElementsByTagName(ib).item(0)||c.documentElement;e.insertBefore(d,e.lastChild);d.src=a;google.a.b.log(yc+a)};
google.a.b.Rc=function(a,c){function d(c){var e=google.a.b.qb,f=a[c++];if(f){var g=f,h=google.a.b.fd[f];h?(g=h,f===sd&&(e=window.document),f=google.a.b.o.third_party):google.a.b.Cb[f]?(g=google.a.b.Cb[f],f=google.a.b.o.third_party_gen):f=google.a.b.Y?n:t?Oa:p;g=f.replace(xd,m).replace(Ad,A).replace(vd,t).replace(wd,g);google.a.b.Tc(g,e);d(c)}}function e(){for(var d=[],e=0;e<a.length;e++)d.push(Rb[a[e]]);eval(Ea+d.join(b)+Cd)();google.a.b.M.setTimeout(c,0)}a=google.a.b.ua(a);for(var f=[],g=0;g<a.length;g++){var h=
a[g];google.a.b.O[h]||f.push(h)}a=f;google.a.b.log(pb+a);var m=google.a.b.o.prefix,n=google.a.b.o.debug,p=google.a.b.o.compiled,Oa=google.a.b.o.i18n,A=google.a.b.P,t=google.a.b.La;t===xc&&(t=null);var Rb={},Sb=a.length;google.a.b.Xc=function(a,c){google.a.b.log(sc+a);Rb[a]=c;google.a.b.O[a]=!0;Sb--;0===Sb&&e()};d(0)};
google.a.b.V=function(a){function c(){g=!0;for(var a=e.length,c=0;c<a;c++)e[c]()}function d(){h=!0;for(var a=f.length,c=0;c<a;c++)f[c]()}var e=[],f=[],g=!1,h=!1;google.a.b.V.count||(google.a.b.V.count=0);var m=Sc+google.a.b.V.count++,n={done:function(a){e.push(a);g&&a();return n},Ba:function(a){f.push(a);h&&a();return n}},p=document.createElement(Qc);p.setAttribute(Nc,m);p.setAttribute(dd,id);p.setAttribute(ld,jd);"undefined"!==typeof p.addEventListener?(p.addEventListener(Rc,c,!1),p.addEventListener(zc,
d,!1)):"undefined"!==typeof p.attachEvent&&p.attachEvent(bd,function(){var a,e=document.styleSheets.length;try{for(;e--;)if(a=document.styleSheets[e],a.id===m){c();return}}catch(f){}g||d()});document.getElementsByTagName(Lc)[0].appendChild(p);p.setAttribute(Mc,a);return n};google.a.b.Oc=function(a,c){google.a.b.log(Tc+a);google.a.b.V(a).done(c).Ba(function(){google.a.b.error(Vc+a)})};
google.a.b.Pc=function(a,c){a=google.a.b.ua(a);var d=google.a.b.Dc(a);if(null===d||0===d.length)c();else{google.a.b.log(qb+d.join(ya));var e=google.a.b.o.prefix,f=google.a.b.o.css;google.a.b.Y&&(f=google.a.b.o.css_debug||f);var g=google.a.b.P,h=function(a){var n=d[a],p;p=a<d.length-1?function(){h(a+1)}:c;google.a.b.O[n]?(google.a.b.log(Na+n),google.a.b.M.setTimeout(p,0)):(google.a.b.O[n]=!0,n=f.replace(xd,e).replace(Ad,g).replace(ud,n),google.a.b.Oc(n,p))};h(0)}};
google.a.b.Ee=function(){var a=google.a.b.C;if(!a){a=google.a.b.C=document.createElement(Oc);google.a.b.C=a;a.name=Ic;(document.body||document.head||document).appendChild(a);a.style.display=Yc;var c=google.a.b.qb=a.contentDocument?a.contentDocument:a.contentWindow?a.contentWindow.document:a.document;c.open();c.writeln(b);c.close()}return a};
google.a.b.zb=function(a){for(var c=a.replace(/-/g,L).toLowerCase();Z.h(c);)a=c,c=google.a.b.languages[c],c===a&&(c=!1);c||(a.match(/_[^_]+$/)?(a=a.replace(/_[^_]+$/,b),a=google.a.b.zb(a)):a=xc);return a};
google.a.b.$c=function(a,c){c.log&&(google.a.b.log=c.log);c.error&&(google.a.b.error=c.error);var d=c.debug,e=c.language||b,e=google.a.b.zb(e);a||(a=c.version||nd);(google.a.b.P&&google.a.b.P!==a||google.a.b.La&&google.a.b.La!==e||google.a.b.Y!==d)&&google.a.b.C&&google.a.b.C.parentNode&&(google.a.b.C.parentNode.removeChild(google.a.b.C),google.a.b.C=null,google.a.b.O={});google.a.b.P=a;google.a.b.La=e;google.a.b.Y=d};google.a.b.qf=!1;google.a.b.Bb=!1;google.a.b.ea=!1;google.a.b.loaded=!1;
google.a.b.Ca=[];
google.a.b.load=function(a,c,d){for(var e=google.a.b.Fb[a];e&&e!==U;)a=e,e=google.a.b.Fb[a];if(google.a.b.Nc)throw Error("google.charts.load() cannot be called more than once.");google.a.b.Nc=!0;google.a.b.Bb=!0;if(google.a.b.ea)google.a.b.T(function(){google.a.b.load(a,c)});else{google.a.b.loaded=!1;google.a.b.ea=!0;google.a.b.$c(a,c);google.a.b.log(Jc+a);google.a.b.o=d||google.a.b.Ta[a]||google.a.b.Ta.gstatic;window.google=window.google||{};google.visualization=google.visualization||{};google.visualization.ModulePath=
google.a.b.o.prefix;google.a.b.C=null;google.a.b.M=window;google.a.b.qb=document;var f=function(){google.a.b.ea=!1;google.a.b.loaded=!0;google.a.b.Ma()},g=c.packages;google.a.b.T(c.callback);google.a.b.Pc(g,function(){google.a.b.Rc(g,f)})}};google.a.b.T=function(a){a&&google.a.b.Ca.push(a);google.a.b.loaded&&!google.a.b.ea&&google.a.b.Ma()};
google.a.b.bd=function(a){if(window.addEventListener)window.addEventListener(Rc,a,!1);else if(window.attachEvent)window.attachEvent(bd,a);else{var c=window.onload;window.onload=function(d){c&&c(d);a()}}};google.a.b.Hb=document&&document.readyState===O;google.a.b.bd(function(){google.a.b.Hb=!0;google.a.b.Ma()});google.a.b.Ma=function(){if(google.a.b.Bb&&google.a.b.loaded&&(google.a.b.Hb||document.readyState===O))for(;0<google.a.b.Ca.length;)google.a.b.Ca.shift()()};
google.a.b.Pa=function(a,c){google.a.b.Xc(a,c)};if(Z.Ec())throw Error("Google Charts loader.js can only be loaded once.");google.a.load=function(a,c,d){a===rd&&(a=c,c=d);google.a.b.load(String(a),c||{})};google.a.T=function(a){google.a.b.T(a)};google.a.Pa=function(a,c){google.a.b.Pa(a,c)};Z.Aa(Ic,google.a.load);Z.Aa("google.charts.setOnLoadCallback",google.a.T);Z.Aa("google.charts.packageLoadedCallback",google.a.Pa); })();
