


if (getCookie("_sj") == "") {
    setCookie("_sj", guid());
}
window.onload = function (opt) {

    function _iniciar() {

        window._bfiltro = "";
        window._bgroupstart = 0;
        //Crea los componentes con la clase syrah.menu

        /*$(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
            console.log("entre")
        });*/

        var elems = getByClass(d, "syrah-slider-3"); //ciencia
        for (var i = 0; i < elems.length; i++) {
            window._s3(elems[i], 'h');
        }

        var elems = getByClass(d, "syrah-slider-2");
        for (var i = 0; i < elems.length; i++) {
            window._s2(elems[i], 'h');
        }

        var elems = getByClass(d, "sy-header");
        var buscadorGlobal = null;
        for (var i = 0; i < elems.length; i++) {
            buscadorGlobal = window._header(elems[i]);
        }
        var elems = getByClass(d, "syrah-menu");
        var buscadorGlobal = null;
        for (var i = 0; i < elems.length; i++) {
            buscadorGlobal = window._menu(elems[i]);
        }
        var elems = getByClass(d, "syrah-minibuscador");
        for (var i = 0; i < elems.length; i++) {
            window._mb(elems[i]);
        }
        var elems = getByClass(d, "syrah-buscador");
        for (var i = 0; i < elems.length; i++) {
            window._b2(elems[i]);
        }

        var elems = getByClass(d, "syrah-slider");
        for (var i = 0; i < elems.length; i++) {
            window._s(elems[i], 'h');
        }

        var elems = getByClass(d, "syrah-img");
        for (var i = 0; i < elems.length; i++) {
            elems[i].onload = function () {
                elems[i].src = elems[i]["data-src"];
            };
        }

        var elems = getByClass(d, "syrah-item");
        for (var i = 0; i < elems.length; i++) {
            window._li(elems[i]);
        }

        var elems = getByClass(d, "syrah-tablas");
        for (var i = 0; i < elems.length; i++) {
            window._ta(elems[i]);
        }

        var elems = getByClass(d, "syrah-cinta");
        for (var i = 0; i < elems.length; i++) {
            elems[i].setAttribute("data-syrah-visible", "3");
            window._s(elems[i], 'h');
        }

        var elems = getByClass(d, "syrah-lista");
        for (var i = 0; i < elems.length; i++) {
            window._lista(elems[i]);
        }
        //lista 
        var elems = getByClass(d, "syrah-lista-conestilo");
        for (var i = 0; i < elems.length; i++) {
            window._listaConEstilo(elems[i]);
        }


        var elems = getByClass(d, "syrah-columnas");
        for (var i = 0; i < elems.length; i++) {
            window._c(elems[i], 'h');
        }

        var elems = getByClass(d, "syrah-iconos");
        for (var i = 0; i < elems.length; i++) {
            window._i(elems[i], 'h');
        }

        var elems = getByClass(d, "syrah-organismo");
        for (var i = 0; i < elems.length; i++) {
            window._o(elems[i], 'h');
        }

        var elems = getByClass(d, "syrah-tabs");
        for (var i = 0; i < elems.length; i++) {
            window._tabs(elems[i]);
        }
        var elems = getByClass(d, "syrah-timeline");
        for (var i = 0; i < elems.length; i++) {
            window._timeline(elems[i]);
        }

        var elems = getByClass(d, "syrah-mapa");
        

        for (var i = 0; i < elems.length; i++) {

            var urlapi = elems[i].getAttribute('data-uri');

            //var urlapidepartamento = elems[i].getAttribute('data-uri-departamentos');

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error, { enableHighAccuracy: true, maximumAge: 1000 });
                navigator.geolocation.watchPosition(function (e) { window._lastgeopos = e; });
            } else {
                error(); // no support
            }
            //window._o(elems[i], 'h');
        }
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(function (e) {
                window._lastgeopos = {
                    lat: e.coords.latitude,
                    lon: e.coords.longitude
                };
            });
        }
        locationManager.iniciar({
            buscadorGlobal: buscadorGlobal

        });


        function success(position) {

            function combodepartamentos() {
                var urlapidepartamento = "https://gestionturismo.sanjuan.gob.ar/api/turismo/mostrarDepartamentos.php";

                var requestdepartamento = new XMLHttpRequest();

                requestdepartamento.onreadystatechange = function () {
                    console.log(requestdepartamento.readyState);
                    if (requestdepartamento.readyState === XMLHttpRequest.DONE) {
                        if (requestdepartamento.status === 200) {
                            var JSONdep = requestdepartamento.response;
                            var listaDepartamentos = JSON.parse(JSONdep);
                            var opciones = document.getElementById("departamentos");
                            for (var i = 0; i < listaDepartamentos.length; i++) {
                                //opciones.options[i]=new Option (listaDepartamentos[i].nombre,listaDepartamentos[i].posicion);
                                opciones.options[i] = new Option(listaDepartamentos[i][0], listaDepartamentos[i][1]);
                            }

                            opciones.addEventListener("change", function () {
                                var cadena = this.value;
                                var a = cadena.split(',');
                                var center = { lat: parseFloat(a[0]), lng: parseFloat(a[1]) };
                                map.setZoom(15);
                                map.setCenter(center);
                            });
                        }
                    }
                }
                requestdepartamento.open('GET', urlapidepartamento);
                requestdepartamento.send();
            };//fin combodepartamentos
            function AgrupamientoLegend() {


                var urlapidepartamento = "https://gestionturismo.sanjuan.gob.ar/api/turismo/mostrarAgrupamiento.php";

                var requestagrup = new XMLHttpRequest();

                requestagrup.onreadystatechange = function () {
                    console.log(requestagrup.readyState);
                    if (requestagrup.readyState === XMLHttpRequest.DONE) {
                        if (requestagrup.status === 200) {
                            var JSONa = requestagrup.response;
                            var listaA = JSON.parse(JSONa);
                            var opciones = document.getElementById("legend2");

                            for (var i = 0; i < listaA.length; i++) {
                                //opciones.options[i]=new Option (listaDepartamentos[i].nombre,listaDepartamentos[i].posicion);
                                //opciones.options[i]=new Option (listaDepartamentos[i][0],listaDepartamentos[i][1]);
                                var botA = document.createElement('a');
                                //botA.type='button';
                                botA.id = listaA[i].clase;
                                //botA.title = listaA[i].nombre;
                                addClass(botA, "w3-button w3-block w3-white w3-left-align");
                                opciones.appendChild(botA);
                                //botA.addEventListener("click",myFunction('" + listaA[i].clase + "'));
                                var ref = listaA[i].clase;
                                botA.addEventListener('click', function () {
                                    myFunction("'" + listaA[i].clase + "'")
                                });

                                botA.innerHTML = '<i class="fa fa-' + listaA[i].clase + '"></i> ' + listaA[i].nombre;
                                //<button onclick="myFunction('dormir')" class="w3-button w3-block w3-white w3-left-align"><i class="fa fa-cutlery"></i> Alojamientos</button>  
                            }
                            //opciones.addEventListener("change", function() 
                            //                                  {   
                            //                                   var cadena = this.value;
                            //                                   var a = cadena.split(',');    
                            //                                   var center = {lat:parseFloat(a[0]), lng:parseFloat( a[1])};
                            //                                   map.setZoom(15);
                            //                                   map.setCenter(center);
                            //                                   });
                        }
                    }
                }
                requestagrup.open('GET', urlapidepartamento);
                requestagrup.send();
            };//fin combodepartamentos

            //esto es lo nuevo para centrar
            function CenterControl(controlDiv, map) {

                // Set CSS for the control border.
                var controlUI = document.createElement('div');
                var botCerrar = document.createElement('span');
                //botCerrar.onclick ='addClass(legend, "w3-hide");';
                addClass(botCerrar, "w3-button w3-tiny w3-red w3-display-topright");
                botCerrar.innerHTML = "×";

                addClass(controlUI, "w3-btn w3-sand");
                controlUI.title = 'Click para centrar el mapa en la Provincia de San Juan';

                controlDiv.appendChild(controlUI);
                controlDiv.appendChild(botCerrar);

                // Set CSS for the control interior.
                var controlText = document.createElement('div');
                addClass(controlText, "w3-padding w3-medium w3-inline");

                controlText.innerHTML = 'Click para centrar el mapa en la Provincia de San Juan   &nbsp;&nbsp;';
                controlUI.appendChild(controlText);

                // Setup the click event listeners: simply set the map to Chicago.
                controlUI.addEventListener('click', function () {
                    map.setCenter(sanjuan);
                });

            }

            //alert('Su posición es: ' + position.coords.latitude + ', ' + position.coords.longitude);

            var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            var options = {
                zoom: 12,
                center: latlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };

            var map = new google.maps.Map(document.getElementById("map"), options);

            //esto es lo nuevo para centar el mapa
            var sanjuan = { lat: -31.53, lng: -68.54 };

            var centerControlDiv = document.createElement('div');
            var centerControl = new CenterControl(centerControlDiv, map);
            centerControlDiv.index = 1;
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

            var image = 'https://hacienda.sanjuan.gob.ar/geo/images/marcadores/aqui-mediano.png';

            var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                title: "Usted se encuentra aca",
                icon: image
            });
            //preguntar si esta en el almacenamiento interno
            //si no esta que cargue de la api

            var url = urlapi;

            var request = new XMLHttpRequest();

            request.onreadystatechange = function () {

                if (request.readyState === XMLHttpRequest.DONE) {
                    if (request.status === 200) {
                        var iconBase = 'https://hacienda.sanjuan.gob.ar/geo/images/marcadores/';

                        var tuJSON = request.response;
                        var localidades = JSON.parse(tuJSON);
                        //var limites = new google.maps.LatLngBounds();
                        //var infowindow = new google.maps.InfoWindow();
                        guardar_localstorage(localidades);
                        var marcador, i;
                        var marcadores = [];
                        var listaMarcadores = [];
                        //var marcadoresTodos;
                        //debugger;
                        for (i = 0; i < localidades.length; i++) {
                            var posi = localidades[i][1].split(',');
                            var titu = localidades[i][0];
                            var tipo = localidades[i][2];
                            var sitio = localidades[i][3];
                            var email = localidades[i][4];
                            var icono = localidades[i][5];
                            var telefono = localidades[i][8];
                            var celular = localidades[i][9];

                            var LatLng = new google.maps.LatLng(posi[0], posi[1]);
                            var dist = getKilometros(posi[0], posi[1], position.coords.latitude, position.coords.longitude);
                            //var urlsug = "window._v(this, "'/gobierno/minturismo/redmine/sugerenciasdesarrolloturistico/s/_f_51532E1F51134661BE0CD5E131E08C7C.html"', "_validaciones")"
                            var contentString = '<div id="content">' +
                                '<div id="siteNotice">' +
                                '</div>' +
                                '<h1 id="firstHeading" class="firstHeading">' + titu + '</h1>' +
                                '<h2 id="secondHeading" class="secondHeading w3-left-align">' + tipo + '</h2>' +
                                '<div id="bodyContent">' +
                                '<p class="w3-left-align"><b>' + titu + '</b>, se encuentra a <b>' + dist + '</b> km. <b>lineales</b> de su ubicación.' +
                                '<p class="w3-left-align"><b>Web: </b><a class="w3-text-uno" dest="_blank" href=http://' + sitio + '>' + sitio + '</a>.' +
                                '<p class="w3-left-align"><b>Email: </b><b class="w3-text-uno">' + email + '</b>.' +
                                '<p class="w3-left-align"><b>Teléfono: </b><b class="w3-text-uno">' + telefono + '--' + celular + '</b>.' +
                                '<a href="https://turismo.sanjuan.gob.ar/#dnwvZ29iaWVybm8vbWludHVyaXNtby9yZWRtaW5lL3N1Z2VyZW5jaWFzZGVzYXJyb2xsb3R1cmlzdGljby9zL19mXzUxNTMyRTFGNTExMzQ2NjFCRTBDRDVFMTMxRTA4QzdDLmh0bWx8X3ZhbGlkYWNpb25lc3x8fHw=" class="w3-btn w3-uno w3-display-bottomright" target="_blank"> Sugerencias</a>.' +
                                '</div>' + '</div>';

                            var TipoMarker = new Object();

                            TipoMarker.tipo = icono;// tiene el tipo de establecimiento

                            var iconTipo = iconBase + 'hotelbar/' + icono + '.png';

                            var marcador = new google.maps.Marker({
                                position: LatLng,
                                map: map,
                                title: titu,
                                icon: iconTipo
                            });


                            TipoMarker.item = marcador;

                            TipoMarker.item.contenidoInfoWindow = contentString;

                            TipoMarker.item.addListener('click', function () {

                                if (infowindow == undefined) {
                                    infowindow = new google.maps.InfoWindow({
                                        content: contentString
                                    });
                                }

                                infowindow.setContent(this.contenidoInfoWindow);
                                infowindow.open(map, this);
                            });

                            listaMarcadores.push(TipoMarker);

                        }//fin del for

                        var legend = document.getElementById('legend');

                        //}// fin if

                        //creo un objeto para los marcadores
                        var TipoIcono = new Object();
                        var ListadoTipoIconos = [];

                        for (i = 0; i < localidades.length; i++) {
                            var name = TipoIcono.tipo = localidades[i][5];//name 
                            var icon = TipoIcono.icon = localidades[i][7]; //.png
                            var carpeta = TipoIcono.icon = localidades[i][6]; //carpeta
                            //var urlico ='http://turismo.sanjuan.gob.ar';
                            var div = document.createElement('div');

                            if (ListadoTipoIconos.length == 0) {
                                ListadoTipoIconos.push(TipoIcono.tipo);
                                addClass(div, "w3-padding-4");
                                div.innerHTML = '<input type="checkbox" class="input' + name + '" checked="checked" id="' + name + '">' +
                                    '<a href="#"> <img style="max-width: 16px;" src="' + iconBase + carpeta + '/' + icon + '"><span class="w3-medium"> ' + name + '</span></a>';

                                legend.appendChild(div);

                                var xx = getByClass(div, "input" + name);

                                xx[0].addEventListener("click", function () {
                                    var valor = this.id;
                                    //alert(this.id);
                                    jsMuestraOculta(valor);
                                });// addEventListener

                            }

                            if (ListadoTipoIconos.indexOf(TipoIcono.tipo) === -1) // no esta en el listado
                            {

                                ListadoTipoIconos.push(TipoIcono.tipo);

                                addClass(div, "w3-padding-4");
                                div.innerHTML = '<input type="checkbox" class="input' + name + '" checked="checked" id="' + name + '">' +
                                    '<a href="#"> <img style="max-width: 16px;" src="' + iconBase + carpeta + '/' + icon + '"><span class="w3-medium"> ' + name + '</span></a>';

                                legend.appendChild(div);

                                var xx = getByClass(div, "input" + name);

                                xx[0].addEventListener("click", function () {
                                    var valor = this.id;
                                    jsMuestraOculta(valor);
                                });// addEventListener

                            }
                            /*else if (ListadoTipoIconos.indexOf(TipoIcono.tipo) > -1) 
                                    {
                                    console.log(TipoIcono.tipo + ' ya esta en la lista.');
                                    }*/


                        }
                        var markerCluster = new MarkerClusterer(map, listaMarcadores.map(function (a) { return a.item; }),
                            { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });

                        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);


                    }//fin del if    

                }
                else {

                }
                /////////////// borrar marcadores ////////////////////////

                //función que realiza la operacion de evaluar los markers y si estos se ocultaran o mostraran dependiendo el checkbox presionado
                function jsMuestraOculta(tipomarcador) {

                    //recorremos todos los markes de la lista
                    for (i = 0; i < listaMarcadores.length; i++) {
                        if (listaMarcadores[i].tipo == tipomarcador) {
                            var muestra = false;
                            //si el checkbox esta checado la variable muestra la ponemos como true
                            if (document.getElementById(tipomarcador).checked) muestra = true;

                            if (muestra)
                                listaMarcadores[i].item.setMap(map);
                            else
                                listaMarcadores[i].item.setMap(null);
                        }
                    }
                }// fin jsMuestraOculta

                // Agrega un marcador al mapa y en el arreglo.
                function agregarMarker(location) {
                    var marcador = new google.maps.Marker({
                        position: location,
                        map: map
                    });
                    markers.push(marcador);
                }

                // Establece el mapa en todos los marcadores en la matriz.
                function setMapOnAll(map) {
                    for (var i = 0; i < marcadores.length; i++) {
                        marcadores[i].setMap(map);
                    }
                }

                // Quita los marcadores del mapa, pero los mantiene en el conjunto.
                function borrarMarkers() {
                    setMapOnAll(null);
                }

                // Muestra cualquier marcador actualmente en la matriz.
                function showMarkers() {
                    alert('mostrar marcador');
                    setMapOnAll(map);
                }

                // Elimina todos los marcadores en la matriz al eliminar las referencias a ellos.
                function deleteMarkers() {
                    alert('borrar marcador');
                    clearMarkers();
                    marcador = [];
                }
                // Removes the markers from the map, but keeps them in the array. limpiar
                function clearMarkers() {
                    alert('ocultar todos los marcadores');
                    setMapOnAll(null);
                }

            };
            request.open('GET', url);
            request.send();
            AgrupamientoLegend();
            combodepartamentos();
        }// fin del success

    }
    _iniciar();



    //////////////// funciones localstorage ///////
    function guardar_localstorage(tuJSON) {
        var listado = JSON.stringify(tuJSON);
        localStorage.setItem("listado", listado);
        //console.log(listado); //string
    }

    function obtener_localstorage() {
        if (localStorage.getItem("listado")) {
            var datos = JSON.parse(localStorage.getItem("listado"));
            //console.log(datos);
        } else {
            console.log("No hay datos en el localStorage.")
        }

    }



};
