window._b2 = function (e) {
    var _input = getByClass(e, "sy-bus-input")[0];
    var _instantList = getByClass(e, "sy-bus-instant-list")[0];
    var cssClassSelected = "sy-cat-selected";
    var _label = getByClass(e, "sy-bus-input")[0];
    var _menu2 = getByClass(d, "sy-menu2")[0];
    var _contenedorResultado = getByClass(d, "sy-bus-result")[0];
    var _contenedorCategorias = getByClass(d, "sy-bus-cat")[0];
    var _contenedorPagina = getByClass(d, "sy-bus-pag")[0];
    addClass(_contenedorPagina, "d-inline-block");
    addClass(_contenedorResultado, "d-none");
    addClass(_contenedorCategorias, "d-none");
    addClass(_contenedorPagina, "d-none");

    var InputFiltroCategorias = getByClass(d, "sy-bus-categ-input")[0];

    var cores = e.getAttribute("data-syrah-cores");
    var categorias = e.getAttribute("data-syrah-categorias").split(",");
    _input.timeout;
    _input.type = "search";
    _input.lastValue = _input.value;
    _input.onpaste = function () {
        _input.onkeyup({
            eventData: {
                forzar: true
            }
        });
    }
    _input.onkeyup = _input.onkeyup || function (ev) {
        _input._bgroupstart = 0;
        _input.instant = false;
        _instantList.innerHTML = "";
        if (ev.keyCode == 13) {
            _input.instant = false;
            ev.eventData = { forzar: true };
        }
        if (_input.lastValue.trim() != _input.value.trim() ||
            (ev.eventData != undefined && ev.eventData.forzar == true)) {
            if ((ev && ev.keyCode == 27) || _input.value == "") {
                //divResult.innerHTML = "";
                //input.esModoLista=false;
                window.ocultarResultados(_contenedorResultado, _contenedorCategorias, _contenedorPagina, _menu2);
            } else {
                clearTimeout(_input.timeout);
                _input.timeout = setTimeout(function () {
                    var consulta = prepararConsulta(_input.value, cores, categorias, undefined, undefined, _input.instant);
                    consulta.element = _contenedorResultado;
                    consulta.userstate = {
                        instant: _input.instant
                    };
                    consulta.callback = function (hres, userstate) {
                        if (userstate.instant) {
                            var listaOpts = hres.split(/\n/);
                            var newListOpt = "";
                            for (var i = 1; i < listaOpts.length - 1; i++) {
                                newListOpt += "<option>" + listaOpts[i] + "</option>";
                            }
                            _instantList.innerHTML = newListOpt;
                        }
                        else {
                            mostarResultados(_contenedorResultado, _contenedorCategorias, JSON.parse(hres), _menu2, _contenedorPagina);
                        }
                    };
                    window._p(consulta);
                }, 0);
            }
        }

        _input.lastValue = _input.value;
    };
    window.ocultarResultados = function (_contenedorResultado, _contenedorCategorias, _contenedorPagina, _menu2) {
        _contenedorResultado.innerHTML = "";
        _contenedorCategorias.innerHTML = "";
        _contenedorPagina.innerHTML = "";
        _input.value = "";
        _input.lastValue = "";
        addClass(_contenedorResultado, "d-none");
        addClass(_contenedorCategorias, "d-none");
        addClass(_contenedorPagina, "d-none");
    }
    function mostarResultados(_contenedorResultado, _contenedorCategorias, jres, _menu2, _contenedorPagina) {
        removeClass(_contenedorResultado, "d-none");
        removeClass(_contenedorCategorias, "d-none");
        removeClass(_contenedorPagina, "d-none");
        _contenedorCategorias.innerHTML = "";
        _cat_tabs = d.createElement("ul");
        addClass(_contenedorCategorias, "d-inline-block");
        _cat_tabs.className = "list-group";
        _contenedorCategorias.style.zIndex = "100";
        _contenedorCategorias.style.left = "0px";
        _contenedorCategorias.appendChild(_cat_tabs);
        var i = 0;
        if (jres.error == undefined) {
            jres.grouped.tipo.groups.forEach(function (g) {

                var a = d.createElement("li");
                var tipo = (!g.groupValue ? "Otros" : g.groupValue);
                a.innerText = tipo;

                a.innerHTML += "<span class=\"badge badge-primary badge-pill\">" + g.doclist.numFound + "</span>";
                a.setAttribute("data-syrah-uri", tipo);
                a.tag = g;
                a.className = "sy-cat-item btn-light list-group-item d-flex justify-content-between align-items-center";
                if (i == 0) {
                    addClass(a, cssClassSelected);
                    addClass(a, cssClassSelected);
                    addClass(a, "active");
                    //removeClass(a, "w3-white");
                }
                a.onclick = function () {
                    //Loguear en google la busqueda
                    var elegido = getByClass(_cat_tabs, cssClassSelected)[0];
                    removeClass(elegido, cssClassSelected);
                    removeClass(elegido, "active");
                    //addClass(elegido, "w3-white");
                    //removeClass(this, "w3-white");
                    addClass(this, cssClassSelected);
                    addClass(this, "active");
                    var tipo = (!this.tag.groupValue ? "Otros" : this.tag.groupValue);
                    _contenedorResultado.innerHTML = "";
                    var consulta = prepararConsulta(_input.value, cores, [], 0, tipo);
                    consulta.callback = function (hres) {
                        cargarCategorias(_contenedorResultado, tipo, this.tag, JSON.parse(hres), _contenedorCategorias, _menu2, _contenedorPagina, 0);
                    };
                    window._p(consulta);

                };
                _cat_tabs.appendChild(a);
                if (i == 0) {
                    a.click();
                }
                i++;
            });
            //agregarFlechasLateralesPaginador(_cat_tabs);

        } else {

        }
    }

    ///Función para preparar la consulta que se hace al buscador
    ///Donde se colocan las categorías 
    function prepararConsulta(valor, core, fq, pagina, tipo, instant) {
        var url = "";
        var qStringLimpio = valor.replace(/[\+\-\&\&\|\|\!\(\)\{\}\[\]\^\"\~\*\?\:\/]/ig, function (a) {
            return "\\" + a;
        }).replace(/\s/ig, "+");

        var query = "q={!parent which=\"nivel:0\"}&fq=" + encodeURI("\"" + qStringLimpio + "\"") + "~10";
        if (instant === true) {
            var coresArray = core.split("/");
            url = "sii/" + coresArray[0] + "/?" + query + "&rows=10";
        }
        else {
            if (fq != undefined && fq != "") {
                for (var i in fq) {
                    query += "&fq=" + i + ":\"" + encodeURI(fq[i]) + "\"";
                }
            }
            var listafq = getCategoriasDeListas();
            if (listafq != undefined && listafq != "") {
                for (var i in listafq) {
                    query += "&fq=categoria:\"" + encodeURI(listafq[i]) + "\"";
                }
            }
            var coresArray = core.split("/");
            if (coresArray != undefined && coresArray != "") {
                for (var i in coresArray) {
                    if (coresArray[i] != "gobierno" && coresArray[i] != "intranet") {
                        query += "&fq=categoria:\"" + encodeURI(coresArray[i]) + "\"";
                    }
                }
            }
            if (tipo != undefined) {
                query += "&fq=tipo:\"" + encodeURI(tipo) + "\"";
            }
            query += "&_v=" + encodeURI("\"" + valor + "\"");
            query += "&defType=dismax";
            if (pagina) {
                if (tipo != undefined && tipo != "") {
                    query += "&start=" + pagina + "&rows=10";
                }
                else {
                    query += "&group.offset=" + pagina;
                }
            }
            url = ((tipo != undefined && tipo != "") ? "si" : "s") + "/" + coresArray[0] + "/?" + query;
        }
        var datos = {
            url: url,
            tipo: 'GET',
            pagina: pagina
        };
        console.log(datos);
        return datos;
    }
    function getCategoriasDeListas() {
        var categorias = [];
        for (var i = 0; i < InputFiltroCategorias.childNodes.length; i++) {
            var pathCategorias = InputFiltroCategorias.childNodes[i].getAttribute("data-sy-cat").split("/");
            for (var j = 0; j < pathCategorias.length; j++) {
                categorias.push(pathCategorias[j]);
            }
        }
        return categorias;
    }

    function agregarPaginadoPorCategoria(_contenedorPagina, g, tipo) {
        _contenedorPagina.innerHTML = "";
        _ulpaginas = d.createElement("div");
        _contenedorPagina.appendChild(_ulpaginas);
        addClass(_ulpaginas, "btn-group container  m-0 p-0");
        _ulpaginas.style.overflowX = "auto";
        _ulpaginas.style.overflowY = "hidden";
        _ulpaginas.setAttribute("role", "group");
        _ulpaginas.setAttribute("aria-label", "Paginas");
        addClass(_ulpaginas, "sy-list-pagina");
        if (g.response.numFound / 10 < 1) {
            removeClass(_contenedorPagina, "d-inline-block");
            addClass(_contenedorPagina, "d-none");
        } else {
            removeClass(_contenedorPagina, "d-none");
            addClass(_contenedorPagina, "d-inline-block");
        }
        for (var i = 1; (i <= g.response.numFound / 10); i++) {
            var a = d.createElement("button");
            a.innerText = i;
            a.setAttribute("type", "button");
            a.className = "btn btn-light";
            if (i == 0) {
                addClass(a, cssClassSelected);
                addClass(a, cssClassSelected);
                addClass(a, "active");
                //removeClass(a, "w3-white");
            }
            a.onclick = function () {
                _contenedorResultado.innerHTML = "";
                var paginaActual = (parseInt(this.innerText) - 1);
                this.parentNode.childNodes.forEach(function (unElem) {
                    removeClass(unElem, "active");
                });

                addClass(this, "active");
                var consulta = prepararConsulta(_input.value, cores, [], paginaActual * 10, tipo);
                consulta.callback = function (hres) {
                    cargarCategorias(_contenedorResultado,
                        tipo,
                        this.tag,
                        JSON.parse(hres),
                        _contenedorCategorias,
                        _menu2,
                        _contenedorPagina,
                        paginaActual);
                };
                window._p(consulta);
            };
            _ulpaginas.appendChild(a);

        }
        //agregarFlechasLateralesPaginador(_ulpaginas);
    }
    function cargarCategorias(_contenedorResultado, tipo, gParent, g, _contenedorCategorias, _menu2, _contenedorPagina, paginaActual) {
        _contenedorResultado.innerHTML = "";
        _contenedorResultado.style.zIndex = "100";
        _contenedorResultado.style.left = "0px";
        if (paginaActual == 0) {
            agregarPaginadoPorCategoria(_contenedorPagina, g, tipo);
        }
        var paginaIndex = _contenedorPagina.firstChild.getElementsByTagName("a");
        for (var i = 0; i < paginaIndex.length; i++) {
            if (paginaActual == i) {
                addClass(paginaIndex[i], "w3-uno");
                removeClass(paginaIndex[i], "w3-white");
            }
            else {
                addClass(paginaIndex[i], "w3-white");
                removeClass(paginaIndex[i], "w3-uno");
            }
        }

        g.response.docs.forEach(function (r) {
            var cssVAcio = "";
            if (r.titulo == undefined || r.titulo == "") {
                r.titulo = "(vacio: " + r.itemid + ")";
                cssVAcio = "w3-red";
            }
            var cssClassSelected = "w3-dos w3-border-red w3-bottombar";
            _item = d.createElement("li");
            _item.className = "sy-bus-item list-group-item d-flex justify-content-between align-items-center " + cssVAcio;

            if (r.click_tipo == undefined) {
                r.click_tipo = "Html_Li";
                r.click = "_v(this,'" + r.resourcename + "','','')";

            }
            else {
                if (r.click == undefined || r.click == "") {
                    if (r.click_tipo == "Html_Li") {
                        _item.innerHTML = r.tmpS;
                        _item = _item.childNodes[0];
                        if (_item.className == undefined) {
                            _item.className = "";
                        }
                        _item.className = _item.className + "sy-bus-item list-group-item d-flex justify-content-between align-items-center " + cssVAcio;
                    }
                    else if (r.click_tipo == "Html_A") {
                        _item.innerHTML = r.tmpS;
                    }
                    else if (r.click_tipo == "Json") {
                        _item.innerHTML = "<i class='fa fa-code'>Datos Abiertos JSON</i>";
                    }
                    else if (r.click_tipo == "Csv") {
                        _item.innerHTML = "<i class='fa fa-code'>Datos Abiertos CSV</i>";
                    }
                    else if (r.click_tipo == "Xml") {
                        _item.innerHTML = "<i class='fa fa-code'>Datos Abiertos XML</i>";
                    }
                }
                else {
                    if (r.click.substring(0, 9) == "recursos/") {
                        r.click = "_r(\"" + r.click + "\")";
                    }
                    _item.appendChild(getItemHtml(r));

                }
            }
            _contenedorResultado.appendChild(_item);
        });

    }
    function evalInContext(js, context) {
        //# Return the results of the in-line anonymous function we .call with the passed context
        return function () { return eval(js); }.call(context);
    }
    function getItemHtml(item) {
        var _item = d.createElement("div");
        _item.className = "w3-card w3-margin";
        v_organismo_sigla = d.createElement("div");
        v_organismo_sigla.className = "organismo_sigla w3-padding";
        v_organismo_color = d.createElement("div");
        v_organismo_color.className = "organismo_color";

        v_tipo = d.createElement("div");
        v_tipo.className = "tipo w3-padding";

        v_titulo = d.createElement("a");
        v_titulo.className = "sy-bus-item-titulo";
        v_titulo.innerText = item.titulo;
        v_titulo.item = item;

        v_titulo.onclick = function () {
            this._r = function (recurso) {
                _v(this, this.getAttribute("data-sy-key") || this.innetText || recurso, undefined, recurso);
            };
            this._v = function (recurso) {
                eval(this.item.click);
            };

            var exp = "this." + this.item.click;
            if (ValidURL(this.item.click)) {
                exp = "window._v(undefined,'',function(){},'" + this.item.click + "')";
            }

            evalInContext(exp, this);
            window.ocultarResultados(_contenedorResultado, _contenedorCategorias, _contenedorPagina, _menu2);
        };
        v_mapaubicacion = d.createElement("div");
        v_mapaubicacion.className = "mapaubicacion";

        v_categoria = d.createElement("div");
        v_categoria.className = "categoria w3-padding";

        v_publish_up = d.createElement("div");
        v_publish_up.className = "publish_up w3-padding";

        v_organismo = d.createElement("div");
        v_organismo.className = "organismo w3-padding";
        v_organismo.appendChild(v_organismo_sigla);
        v_organismo.appendChild(v_publish_up);
        v_organismo.appendChild(v_categoria);

        if (!item.organismo_nombre) {
            item.organismo_nombre = ["Otros"];
        }
        if (item.organismo_sigla) {
            v_organismo_sigla.innerText = item.organismo_sigla;
        }
        else {
            v_organismo_sigla.innerText = item.organismo_nombre.subString(0, 2) + "...";
        }
        v_organismo_sigla.setAttribute("title", item.organismo_nombre);
        v_organismo_color.style.backgroundColor = item.organismo_color;
        v_tipo.innerText = item.tipo;

        item.categoria.forEach(function (element) {
            var li = d.createElement("div");
            li.innerText = element;
            v_categoria.appendChild(li);
        }, this);



        li = d.createElement("div");
        li.innerText = (new Date(item.publish_up)).toLocaleString();
        v_publish_up.appendChild(li);

        _item.appendChild(v_titulo);
        _item.appendChild(v_organismo_color);
        _item.appendChild(v_organismo);

        return _item;
    }
}

window._b_act = function (contenedor, syListConfig, btn) {
    if (!hasClass(btn, 'sy-busc2-open')) {
        addClass(btn, 'sy-busc2-open');
    }
    if (syListConfig != undefined) {
        window.bus_cat_add(contenedor, syListConfig.titulo1, syListConfig.core);
    }
};

window.bus_cat_add = function (contenedor, catTit, catVal) {
    var syCategorias = getByClass(contenedor, "sy-bus-categ-input")[0];
    var syBusInput = getByClass(contenedor, "sy-bus-input")[0];
    var catSpan = d.createElement("span");
    var closeBtn = d.createElement("i");
    closeBtn.className = "fa fa-times";
    closeBtn.onclick = function () {
        syCategorias.removeChild(catSpan);
        triggerEvent(syBusInput, "keyup", { forzar: true });
    };
    catSpan.setAttribute("data-sy-cat", catVal);
    catSpan.innerHTML = catTit;
    catSpan.className = "w3-tag w3-transparent tinta_oscuro";
    setTimeout(function () {
        addClass(catSpan, "animated tada");
    }, 200);
    catSpan.appendChild(closeBtn);
    syCategorias.appendChild(catSpan);
    triggerEvent(syBusInput, "keyup", { forzar: true });
};