/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 * nito
 */

var infowindow;

getKilometros = function(lat1,lon1,lat2,lon2)
 {
	rad = function(x) {return x*Math.PI/180;}
	var R = 6378.137; //Radio de la tierra en km
	var dLat = rad( lat2 - lat1 );
	var dLong = rad( lon2 - lon1 );
	var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d = R * c;
	return d.toFixed(3).replace('.',','); //Retorna tres decimales
 }

function initMap() {
  var myLatLng = {lat: -31.5372765, lng: -68.5404907};

  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 4,
    center: myLatLng,
    mapTypeId: 'roadmap'
  });
  

}

function error() {
        
        var textoErr = "<a href='paginaEnConstruccion.html' onclick='alert('Seccion sin terminar');'";
        var card;
        var err = document.querySelector('.error');
        err.cloneNode(true);
        //err.appendChild(textoErr);
	alert('Debe tener activado el GPS o Ubicación en su dispositivo Celular.');
}


function get(url) {
  // Return a new promise.
  return new Promise(function(resolve, reject) {
    // Do the usual XHR stuff
    var req = new XMLHttpRequest();
    req.open('GET', url);

    req.onload = function() {
      // This is called even on 404 etc
      // so check the status
      if (req.status == 200) {
        // Resolve the promise with the response text
        resolve(req.response);
      }
      else {
        // Otherwise reject with the status text
        // which will hopefully be a meaningful error
        reject(Error(req.statusText));
      }
    };

    // Handle network errors
    req.onerror = function() {
      reject(Error("Network Error"));
    };

    // Make the request
    req.send();
  });
}

function centrarSanJuan(la,lo)
        {
            /*var myOptions = {
            center: new google.maps.LatLng(la, lo),
            zoom: 3,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            map
          };*/
          
          //var map = google.maps.Map(document.getElementById("map"), myOptions);
          var center = {lat:la, lng:lo};
          map.setZoom(15);
          map.setCenter(center);
        }
