window._tabs = function (container, vertical) {
    var cabeceras = d.createElement("ul");
    cabeceras.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);


    container.appendChild(cabeceras);
    cabeceras.setAttribute("class", "nav nav-tabs");
    cabeceras.setAttribute("role", "tablist");
    window._divcol(cabeceras, function () {

    });
};

