//nito

window._divcol = function (syrahItemDiv, callback) {
    var uri = "gen" + syrahItemDiv.attributes["data-syrah-uri2"].value;
    window._p(
        {
            element: syrahItemDiv,
            url: uri,
            tipo: 'GET',
            data: {},
            callback: function (_hres) {
                syrahItemDiv.innerHTML = _hres;
                if (callback) {
                    callback(syrahItemDiv);
                }
            }
        }
    );
};
//nito
window._c = function (container, vertical) {
    var divcol = d.createElement("div");
    divcol.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);


    container.appendChild(divcol);
    container.children[0].setAttribute("class", "row mb-3");
    window._divcol(container.children[0], function () {
        var col3 = d.createElement("div");
        col3.setAttribute("class", "col-3");
        container.appendChild(col3);
    });
};
