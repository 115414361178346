/**
 * Crea a partir de un contenedor (abajo se coloca el template), encargado de armar el menú y el buscador
 * <nav class="syrah-menu col-6 col-m-12" data-syrah-res-id="seccionBuscador" data-syrah-cores="gobierno">
 <a href="#menu" data-icono="fa fa-home" >San Juan</a>
 <a href="#gobierno" data-icono="fa fa-sitemap" >Gobierno</a>
 </nav>
 * **/
window._menu = function (contenedor) {
    for (var i = 0; i < contenedor.childNodes.length; i++) {
        var menuItem = contenedor.childNodes[0];
        menuItem.childNodes.addEventListener("click", function () {
            if (hasClass(menuItem, "activo")) {
                removeClass(menuItem, "activo");
            } else {
                addClass(menuItem, "activo");
            }
        });
    }
    return {};
}

