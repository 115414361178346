window.insertAfter = function (newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}
window.ValidURL = function (str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
}
window._getX = function () {
    var x = false, XMLHttpRequestObjects = [
        function () {
            return new window.XMLHttpRequest();  // IE7+, Firefox, Chrome, Opera, Safari
        },
        function () {
            return new window.ActiveXObject('Msxml2.XMLHTTP.6.0');
        },
        function () {
            return new window.ActiveXObject('Msxml2.XMLHTTP.3.0');
        },
        function () {
            return new window.ActiveXObject('Msxml2.XMLHTTP');
        }
    ];
    for (i = 0; i < XMLHttpRequestObjects.length; i += 1) {
        try {
            x = XMLHttpRequestObjects[i]();
        } catch (ignore) {
        }

        if (x !== false) {
            break;
        }
    }
    return x;
};

window.jsonToQueryString = function (json) {
    if (json == undefined || json == null || Object.keys(json).length == 0) {
        return "";
    } else {
        return '?' +
            Object.keys(json).map(function (key) {
                return encodeURIComponent(key) + '=' +
                    encodeURIComponent(json[key]);
            }).join('&');
    }
}

window.hasClass = function (elem, clase) {
    var rg = new RegExp("[\s]{0,1}" + clase + "[\s]{0,1}", "gi");
    return elem.className.match(rg);
}
window.addClass = function (elem, clase) {
    var rg = new RegExp("[\s]{0,1}" + clase + "[\s]{0,1}", "gi");
    if (elem != undefined && elem.className != undefined && !elem.className.match(rg)) {
        if (elem.className.length == 0 || elem.className[elem.className.length - 1] != " ") {
            elem.className += " ";
        }
        elem.className += clase;
    }
}

window.removeClass = function (elem, clase) {
    var rg = new RegExp("[\s]{0,1}" + clase + "[\s]{0,1}", "gi");
    if (elem != undefined && elem.className != undefined && elem.className.match(rg)) {

        elem.className = elem.className.replace(rg, "");
    }
}

window.removeChildByClass = function (elem, className) {
    var els = getByClass(elem, className);
    for (var i = els.length - 1; i >= 0; i--) {
        els[i].parentNode.removeChild(els[i]);
    }
}

var onResize = function (element, callback) {
    if (!onResize.watchedElementData) {
        // First time we are called, create a list of watched elements
        // and hook up the event listeners.
        onResize.watchedElementData = [];

        var checkForChanges = function () {
            onResize.watchedElementData.forEach(function (data) {
                if (data.element.offsetWidth !== data.offsetWidth ||
                    data.element.offsetHeight !== data.offsetHeight) {
                    data.offsetWidth = data.element.offsetWidth;
                    data.offsetHeight = data.element.offsetHeight;
                    data.callback();
                }
            });
        };

        // Listen to the window's size changes
        window.addEventListener('resize', checkForChanges);

        // Listen to changes on the elements in the page that affect layout 
        var observer = new MutationObserver(checkForChanges);
        observer.observe(document.body, {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true
        });
    }

    // Save the element we are watching
    onResize.watchedElementData.push({
        element: element,
        offsetWidth: element.offsetWidth,
        offsetHeight: element.offsetHeight,
        callback: callback
    });
};

window.on = function (elem, evento, _cb) {
    elem.addEventListener(evento, _cb);
}
var scrollInterval;
window.moverScroll = function (elemId, margenTop, _cb) {
    if (d.getElementById(elemId.substring(1))) {
        var t = d.getElementById(elemId.substring(1)).offsetTop - margenTop;
        var l = d.getElementById(elemId.substring(1)).offsetLeft;
        window.scroll(l, t);
    }
}

navigator._getBrowser = function () {
    var ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null)
            return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
        M.splice(1, 1, tem[1]);
    return M.join(' ');
};

window.guid = function () {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}
window.uid = window['uid'] || guid();
window.getCookie = function (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
window.setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
window.getByClass = function (d, c) {
    if (!d) {
        d = document;
    }
    if (d.getElementsByClassName) {
        return d.getElementsByClassName(c);
    } else {
        return d.querySelectorAll("." + c);
    }
};

window.getFuenteString = function (uriRelativo) {
    return "@font-face { " +
        "font-family: 'FontAwesome'; " +
        "src: url('" + uriRelativo + "font/fontawesome-webfont.eot?v=4.6.3'); " +
        "src: url('" + uriRelativo + "font/fontawesome-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'), url('" + uriRelativo + "font/fontawesome-webfont.woff2?v=4.6.3') format('woff2'), url('" + uriRelativo + "font/fontawesome-webfont.woff?v=4.6.3') format('woff'), url('" + uriRelativo + "font/fontawesome-webfont.ttf?v=4.6.3') format('truetype'), url('" + uriRelativo + "font/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular') format('svg'); " +
        "font-weight: normal; " +
        "font-style: normal; " +
        "} " +
        "@font-face { font-family: 'gob';src:url('" + uriRelativo + "font/brokman-regular-webfont.eot');src:url('" + uriRelativo + "font/brokman-regular-webfont.eot') format('embedded-opentype'),url('" + uriRelativo + "font/brokman-regular-webfont.woff') format('woff'),url('" + uriRelativo + "font/brokman-regular-webfont.ttf') format('truetype'),url('" + uriRelativo + "font/brokman-regular-webfont.svg') format('svg');font-weight: normal;font-style: normal;} ";
}

window.appendHtml = function (el, str) {
    var div = document.createElement('div');
    div.innerHTML = str;
    while (div.children.length > 0) {
        el.appendChild(div.children[0]);
    }
}

window.addCss = function (elem, propName, value) {
    // Set the non-vendor version
    elem.style[propName] = value;

    // Make first char capped
    propName = propName.substring(0, 1).toUpperCase() + propName.substring(1);

    // Set vendor versions
    elem.style["Webkit" + propName] = value;
    elem.style["Moz" + propName] = value;
    elem.style["O" + propName] = value;
    elem.style["Ms" + propName] = value;

    // Done
    return value;
}

window.triggerEvent = function (el, type, eventData) {
    if ('createEvent' in document) {
        // modern browsers, IE9+
        var e = document.createEvent('HTMLEvents');
        e.eventData = eventData;
        e.initEvent(type, false, true);
        el.dispatchEvent(e);
    } else {
        // IE 8
        var e = document.createEventObject();
        e.eventType = type;
        e.eventData = eventData;
        el.fireEvent('on' + e.eventType, e);
    }
}