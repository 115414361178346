window._s = function (container, vertical) {
    function setModoBuscador(_ul) {
        removeClass(_ul, "sinscroll");
        removeClass(_ul, "conscroll");
        addClass(_ul, "conscroll");

        _ul.lastUlHTML = d.createElement("ul");
        for (var ii = _ul.childNodes.length - 1; ii >= 0; ii--) {
            //Lo volvemos a invertir
            var auxNode = _ul.childNodes[ii];
            _ul.removeChild(_ul.childNodes[ii]);
            _ul.lastUlHTML.appendChild(auxNode);
        }
    }
    function setModoLista(_ul) {
        _ul.innerHTML = "";
        for (var ii = _ul.lastUlHTML.childNodes.length - 1; ii >= 0; ii--) {
            //Lo volvemos a invertir
            var auxNode = _ul.lastUlHTML.childNodes[ii];
            _ul.lastUlHTML.removeChild(_ul.lastUlHTML.childNodes[ii]);
            _ul.appendChild(auxNode);
        }
    }
    var ul = d.createElement("ul");
    ul.className = "w3-ul w3-card-2";
    ul.setAttribute("data-syrah-uri", container.attributes["data-syrah-uri"].value);
    var curSlider = container;
    window._li(ul, function () {
        var bprev = undefined;
        var bnext = undefined;
        if (vertical == 'v') {
            var bloadMas = d.createElement("button");
            bloadMas.className = "sy-load-mas";
            bloadMas.innerHTML = "<i class='fa fa-angle-down' ></i> Leer mas...";
            bloadMas.style.position = "absolute";
            bloadMas.paso = ul.childNodes.length;
            addClass(ul, "sinscroll");
            bloadMas.addEventListener("click", function () {
                bloadMas.style.position = "initial";
                removeClass(ul, "sinscroll");
                addClass(ul, "conscroll");
                if (bloadMas.autoclickonscroll == undefined) {
                    bloadMas.autoclickonscroll = true;
                    ul.onscroll = function (e) {
                        if (e.target.scrollHeight ==
                                e.target.scrollTop +
                                ul.clientHeight) {
                            bloadMas.click();
                        }
                        e.stopPropagation();
                    };
                }
                window._liPage(bloadMas, ul, container.attributes["data-syrah-uri"].value, function () {

                });
            });
            //bprev.innerHTML = "<i class='fa fa-angle-up' ></i>";
            //bprev.className = "syrah-button top";
            //bnext.className = "syrah-button bottom";
            //bnext.innerHTML = "<i class='fa fa-angle-down' ></i>";
            var headerLista = null;
            if (container.getAttribute("data-syrah-titulo1") != null) {
                headerLista = d.createElement("div");
                var iconHml = "";
                if (container.getAttribute("data-syrah-icono") != null)
                {
                    iconHml = "<i class='" + container.getAttribute("data-syrah-icono") + "' ></i> ";
                }
                headerLista.innerHTML = "<h3>" + iconHml + container.getAttribute("data-syrah-titulo1") + "</h3>";
                headerLista.className = "w3-bottombar w3-border-uno w3-bottombar sy-list-head";

            }
            if (container.getAttribute("data-syrah-core")) {

                var pieLista = d.createElement("div");
                pieLista.className = "sy-list-footer";
                pieLista.innerHTML = "<i class='fa fa-search'></i>";
                var inputBuscador = d.createElement("input");
                inputBuscador.setAttribute("type", "text");
                if (headerLista != null) {
                    inputBuscador.setAttribute("placeholder", "Buscar en " + container.getAttribute("data-syrah-titulo1"));
                }
                inputBuscador.className = "sy-list-vermas";
                inputBuscador.esModoLista = true;
                inputBuscador.addEventListener("focus", function () {
                    //addClass(container, "enfocado");

                });
                inputBuscador.addEventListener("blur", function () {
                    //removeClass(container, "enfocado");
                });
                inputBuscador._sliderOnkeyup = function () {
                    if (inputBuscador.value == "" && !inputBuscador.esModoLista) {
                        inputBuscador.esModoLista = true;
                        setModoLista(ul);
                    } else {
                        if (inputBuscador.esModoLista && inputBuscador.value != "") {
                            inputBuscador.esModoLista = false;
                            setModoBuscador(ul);
                        }
                    }
                };
                inputBuscador.addEventListener("keyup", function (e) {
                    inputBuscador._sliderOnkeyup();

                });

                addClass(container, "enfocado");
                var cores = [];
                var existeCores = container.getAttribute("data-syrah-core");
                if (existeCores != undefined && existeCores != "") {
                    cores = existeCores.split("_");
                }




//                window._baddKeyUp(inputBuscador, ul, cores, function (input_value) {
//
//                });
                ul.appendChild(bloadMas);

                pieLista.appendChild(inputBuscador);
                if (headerLista != null) {
                    curSlider.appendChild(headerLista);
                }
                curSlider.appendChild(ul);
                curSlider.appendChild(pieLista);
            } else {
                ul.appendChild(bloadMas);
                if (headerLista != null) {
                    curSlider.appendChild(headerLista);
                }
                curSlider.appendChild(ul);
            }
        } else {
            bprev = d.createElement("button");
            bnext = d.createElement("button");
            bprev.innerHTML = "&#10094;";
            bprev.className = "w3-button w3-light-grey noselection w3-display-left";
            bnext.className = "w3-button w3-light-grey noselection w3-display-right";
            bnext.innerHTML = "&#10095;";
            curSlider.appendChild(ul);
            curSlider.appendChild(bprev);
            curSlider.appendChild(bnext);
        }

        var visible = 1;
        if (curSlider.attributes["data-syrah-visibles"]) {
            visible = parseInt(curSlider.attributes["data-syrah-visibles"].value);
        }
        var autoplay = "";
        if (curSlider.attributes["data-syrah-autoplay"]) {
            var autoplay = parseInt(curSlider.attributes["data-syrah-autoplay"].value);
        }
        if (isNaN(autoplay)) {
            autoplay = 5000;
        }
        var single = new Slider(curSlider, {
            controlNext: bnext,
            controlPrev: bprev,
            visibles: visible,
            autoPlay: (vertical == 'h' ? autoplay : 0),
            direction: (vertical == 'h' ? 'horizontal' : 'vertical')
        }
        );
        
        var idname = "";
        if (container.attributes["id"] != null) {
            idname = container.attributes["id"].value.toString();
        }
        //console.log("idname= " + idname.length);
        if (idname.indexOf('syrah-imagen') >= 0 && idname.length>0) {
            //console.log("HOSPITAL MARCIAL QUIEROGA")
            funcion2();
        } //else console.log("OTRA PAGINA")
        
    });

    function funcion2() {
        var elems = getByClass(d, "sy-item syrah-opcion");
        for (var i = 0; i < elems.length; i++) {
            elems[i].onclick = null;
        }
    };
};
/**
 * Vanilla Slider
 * A simple slider made with pure Javascript
 *
 * @version 0.1
 * @author Guilherme Santiago - http://guilherme.sexy
 * @repo http://github.com/gsantiago/vanilla-slider
 */
(function (window, document) {
    'use strict';
    /**
     * Default options
     */
    var DEFAULTS = {
        visibles: 1,
        direction: 'horizontal',
        controlPrev: '',
        controlNext: '',
        justify: true,
        steps: 1,
        // TO IMPLEMENT:
        dots: '',
        touch: true,
        animation: 'slide',
        autoPlay: 1000,
        infinite: true,
    };
    /**
     * Utils
     */
    var utils = {
        // Returns true if 'obj' is a node element
        isDOM: function (o) {
            return (
                    typeof HTMLElement === 'object' ? o instanceof HTMLElement :
                    o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string'
                    );
        },
        // Merge two objects. Similar to $.extends from jQuery
        merge: function (obj1, obj2) {
            var result = {};
            for (var prop in obj1) {
                if (obj2.hasOwnProperty(prop)) {
                    result[prop] = obj2[prop];
                } else {
                    result[prop] = obj1[prop];
                }
            }

            return result;
        },
        // A simple iterator for collections
        each: function (group, callback) {
            for (var i = 0, max = group.length; i < max; i += 1) {
                callback.call(group[i], i);
            }
        }
    }

    /**
     * Constructor for the slider
     *
     * @class Slider
     * @constructor
     * @param {String | Node Element} Selector for the slider's container
     * @param {Object} Options
     */
    function Slider(element, options) {
        if (!(this instanceof Slider))
            return new Slider(element, options);
        if (utils.isDOM(element)) {
            this.container = element;
        } else {
            this.container = document.querySelector(element);
        }

        this.settings = utils.merge(DEFAULTS, options);
        this.slider = this.container.children[0];
        this.items = this.slider.children;
        this.itemMargin = 0;
        this.autoPlayInterval = 0;
        var _this = this;
        _this.autoMove = function (_t) {
            if (_t.settings.autoPlay > 0) {
                clearInterval(_t.autoPlayInterval);
                _t.autoPlayInterval = setInterval(function () {
                    if (!_t.move(1)) {
                        _t.slider.style[_this.settings.direction === 'vertical' ? 'top' : 'right'] = "0px";
                        _t.getNextPos(0);
                    }
                }, _t.settings.autoPlay);
            }
        };
        _this.autoMove(_this);
        function updatePosSlider() {
            _this.containerWidth = _this.container.offsetWidth;
            _this.containerHeight = _this.container.offsetHeight;
            if (_this.items.length > 0) {
                _this.itemWidth = _this.items[0].offsetWidth;
                addClass(_this.items[0], "sy-select");
                _this.itemHeight = _this.items[0].offsetHeight;
            }
            if (_this.settings.direction === 'vertical') {
                _this.containerDimension = _this.containerHeight;
                _this.itemDimension = _this.itemHeight;
            } else {
                _this.containerDimension = _this.containerWidth;
                _this.itemDimension = _this.itemWidth;
            }
            _this.slider.style[_this.settings.direction === 'vertical' ? 'top' : 'right'] = "0px";
            _this.getNextPos(0);
        }
        function onOver(ev) {
            if (ev.touches)
            {
                _this.startY = ev.touches[0].screenY;
                _this.startX = ev.touches[0].screenX;
            }
            _this.touchPaso = 10;
            _this.isOver = true;
        }
        function offOver() {
            _this.isOver = undefined;
        }
        function onWell(ev) {
            if (_this.isOver) {

                if (ev.deltaY) {
                    if (ev.deltaY < 0) {
                        if (_this.move(-1)) {
                            ev.preventDefault();
                        }
                    } else if (ev.deltaY > 0) {
                        if (_this.move(1)) {
                            ev.preventDefault();
                        }
                    }
                } else if (ev.touches) {
                    if (_this.settings.direction === 'vertical') {
                        if (_this.startY > ev.touches[0].screenY) {
                            if (_this.move(-1)) {
                                ev.preventDefault();
                                _this.startY = ev.touches[0].screenY;
                                _this.startX = ev.touches[0].screenX;
                            }
                        } else if (_this.startY < ev.touches[0].screenY) {

                            if (_this.move(1)) {
                                _this.startY = ev.touches[0].screenY;
                                _this.startX = ev.touches[0].screenX;
                                ev.preventDefault();
                            }
                        }

                    } else {
                        if (_this.startX > ev.touches[0].screenX) {
                            _this.move(-1);
                        } else {
                            _this.move(1);
                        }
                    }

                }

            }

        }
        window.addEventListener('resize', updatePosSlider);
//        if (_this.settings.direction === 'horizontal') {
//            this.container.addEventListener('mouseover', onOver);
//            this.container.addEventListener('mouseleave', offOver);
//            this.container.addEventListener('mousewheel', onWell);
//            this.container.addEventListener('touchmove', onWell);
//            this.container.addEventListener('touchstart', onOver);
//            this.container.addEventListener('touchend', offOver);
//        }
        updatePosSlider();
// Justify the margins acording to the number of items to show (visibles)
        if (this.settings.visibles > 1 && this.settings.justify) {
            this.justifyItems();
        }

        if (this.settings.controlNext || this.settings.controlPrev) {
            this.addControls(this.settings.controlNext, this.settings.controlPrev, this.settings.steps);
        }
    }

// An alias for prototype
    Slider.fn = Slider.prototype;
    /*
     * Justify the margins between the items acording to the number
     * of items to show (the visibles), and the direction (vertical or horizontal)
     *
     * @method justifyItems
     */
    Slider.fn.justifyItems = function () {

        var visibles = this.settings.visibles,
                items = this.items,
                direction = this.settings.direction,
                margin;
        margin = (this.containerDimension - (this.itemDimension * visibles)) / (visibles - 1);
        margin = Math.ceil(margin);
        this.itemMargin = margin;
        utils.each(items, function () {
            this.style[direction === 'vertical' ? 'marginBottom' : 'marginRight'] = margin + 'px';
        });
    };
    /**
     * Static method to return the max or min position for the Slider
     *
     * @method getLimit
     * @param {Object} The instance of slider
     * @param {String} The position ('max' or 'min')
     */
    Slider.getLimit = function (instance, pos) {

        var settings = instance.settings,
                direction = settings.direction,
                itemDimension = instance.itemDimension,
                itemMargin = instance.itemMargin,
                visibles = settings.visibles,
                items = instance.items;
        if (direction === 'vertical' && pos === 'max') {
            return 0;
        }

        if (direction === 'vertical' && pos === 'min') {
            return (((itemDimension + itemMargin) * (items.length - visibles + 1)) - itemMargin) * -1;
        }

        if (direction === 'horizontal' && pos === 'max') {
            return ((itemDimension + itemMargin) * (items.length - visibles + 1)) - itemMargin;
        }

        if (direction === 'horizontal' && pos === 'min') {
            return 0;
        }

    };
    /**
     * Check if the Slider is at its limit
     *
     * @method isAtLimit
     */
    Slider.fn.isAtLimit = function (nextPos) {
        var max = Slider.getLimit(this, 'max'),
                min = Slider.getLimit(this, 'min');
        if (nextPos < min) {
            return true;
        }

        if (nextPos > max - 1) {
            return true;
        }

        return false;
    };
    /**
     * Return the next position for the slider based in the number of steps
     *
     * @method getNextPos
     */
    Slider.fn.getNextPos = function (steps) {

        var currentPos,
                direction,
                nextPos;
        direction = this.settings.direction;
        currentPos = this.slider.style[direction === 'vertical' ? 'top' : 'right'];
        if (currentPos) {
            currentPos = Math.floor(parseInt(currentPos));
        }

        if (direction === 'vertical') {
            nextPos = currentPos - (((this.itemDimension + this.itemMargin)) * steps);
        } else {
            nextPos = currentPos + (((this.itemDimension + this.itemMargin)) * steps)
        }

        return Math.ceil(nextPos);
    };
    /**
     * Move the slider.
     * Pass a negative number to go in the inverse direction
     *
     * @method move
     * @param {Integer} Number of items to move
     */
    Slider.fn.move = function (steps) {
        steps = steps || 1;
        var nextPos = this.getNextPos(steps),
                direction = this.settings.direction;
        if (this.isAtLimit(nextPos)) {
            return false;
        }
        var iiItem = Math.ceil(nextPos / (this.itemDimension + this.itemMargin));

        for (var ii = 0; ii < this.slider.childNodes.length; ii++) {

            if (iiItem == ii) {
                addClass(this.slider.childNodes[ii], "sy-select");
            } else {
                removeClass(this.slider.childNodes[ii], "sy-select");
            }
        }

        this.slider.style[direction === 'vertical' ? 'top' : 'right'] = nextPos + 'px';
        return true;
    };
    /**
     * Add event listeners for the controls passed
     *
     * @method addControls
     * @param {String | Node Element} Next Control
     * @param {String | Node Element} Prev Control
     * @param {Integer} Number of items (steps) to avance/return
     */
    Slider.fn.addControls = function (next, prev, steps) {
        var that = this;
        if (!utils.isDOM(next)) {
            next = document.querySelector(next);
        }

        if (!utils.isDOM(prev)) {
            prev = document.querySelector(prev);
        }

        next.addEventListener('click', function (e) {
            e.preventDefault();
            clearInterval(that.autoPlayInterval);
            setTimeout(function () {
                that.autoMove(that);
            }, 10000);
            that.move(steps);
        });
        prev.addEventListener('click', function (e) {
            e.preventDefault();
            clearInterval(that.autoPlayInterval);
            setTimeout(function () {
                that.autoMove(that);
            }, 10000);
            that.move(steps * -1);
        });
    };
    window.Slider = Slider;
}(window, document));

window._itemsslider = function (syrahItemDiv, callback) {
    //syrahItemDiv.setAttribute("class", "row");
    var uri = "gen" + syrahItemDiv.attributes["data-syrah-uri2"].value;
    window._p(
            {
                element: syrahItemDiv,
                url: uri,
                tipo: 'GET',
                data: {},
                callback: function (_hres) {
                    syrahItemDiv.innerHTML = _hres;
                    if (callback) {
                        callback(syrahItemDiv);
                    }
                }
            }
    );
};

window._s2 = function (container, vertical) {
    var divcol = d.createElement("ul");
    divcol.setAttribute("id", "sb-slider");
    divcol.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);
    addClass(divcol, "sb-slider");

    container.appendChild(divcol);
    window._itemsslider(container.children[0], function () {

        //container.appendChild(col3);



        var Page = (function () {

            var $navArrows = $('#nav-arrows').hide(),
                    $shadow = $('#shadow').hide(),
                    slicebox = $('#sb-slider').slicebox({
                onReady: function () {

                    $navArrows.show();
                    $shadow.show();

                },
                orientation: 'h',
                cuboidsRandom: true,
                disperseFactor: 30,
                interval: 4500,
            }),
                    init = function () {

                        initEvents();

                    },
                    initEvents = function () {

                        // add navigation events
                        $navArrows.children(':first').on('click', function () {

                            slicebox.next();
                            return false;

                        });

                        $navArrows.children(':last').on('click', function () {

                            slicebox.previous();
                            return false;

                        });

                    };

            return {init: init};

        })();
        //alert('no sabben');    
        Page.init();

    });

};

window._s3 = function (container, vertical) {
    var divcol = d.createElement("ul");
    //divcol.setAttribute("id", "sb-slider");
    divcol.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);
    //addClass(divcol, "sb-slider");

    container.appendChild(divcol);
    window._itemsslider(container.children[0], function () {

        inicial(); // carrucel.js

    });

};

/*window._c = function (container, vertical) {
 var divcol = d.createElement("div");
 divcol.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);
 
 
 container.appendChild(divcol);
 window._divcol(container.children[0], function () {
 var col3 = d.createElement("div");
 col3.setAttribute("class", "col-3");
 container.appendChild(col3);
 });
 };*/
