//Función para ejecutar ajax en el servidor local y de imagenes


window._p = function (options) {
    options = {
        url: (options.url || ""),
        tipo: (options.tipo || "GET"),
        data: (options.data || {}),
        userstate: (options.userstate || undefined),
        callback: (options.callback || function (res) {

        }),
        element: (options.element || undefined)
    };


    var x = _getX();
    var s;
    if (options.tipo == 'POST') {
        s = JSON.stringify(options.data);
    } else if (options.tipo == 'GET' || options.tipo == 'GET-HTML') {
        if (options.url.search(/\?/) >= 0) {
            s = "&" + jsonToQueryString(options.data).substring(1);
        } else {
            s = jsonToQueryString(options.data);
        }
    }
    if (options.element) {
        removeChildByClass(options.element, "sy-ajax-loading");
        var loading = d.createElement("div");
        loading.className = "sy-ajax-loading";
        options.element.appendChild(loading);
    }
    x.onreadystatechange = function () {
        if (x.readyState == XMLHttpRequest.DONE) {
            if (options.element) {
                removeChildByClass(options.element, "sy-ajax-loading");
            }
            if (x.status == 200) {
                options.callback(x.responseText, options.userstate);

            } else {
                if (options.tipo == 'GET-HTML')
                {
                    options.callback("", options.userstate);
                } else {
                    options.callback(JSON.stringify({error: x.responseText}), options.userstate);
                }
            }
        }
    };
    x.onerror = function () {
        var err = {
            error: x.statusText
        };
        if (options.element) {
            removeChildByClass(options.element, "sy-ajax-loading");
        }
        options.callback(err, options.userstate);
    };
    x.onabort = function () {
        var err = {
            error: x.statusText
        };
        if (options.element) {
            removeChildByClass(options.element, "sy-ajax-loading");
        }
        options.callback(err, options.userstate);
    }
    
    if (options.tipo == 'POST') {
        x.open("POST", options.url);
        x.setRequestHeader('Content-type', 'application/json; charset=utf8');
        x.setRequestHeader('UserId', window.uid);
        x.send(s);
    } else if (options.tipo == 'GET-HTML') {
        x.open("GET", options.url + s);
        x.setRequestHeader('Content-type', 'application/html; charset=utf8');
        x.setRequestHeader('UserId', window.uid);
        x.send("");
    } else if (options.tipo == 'GET') {
        x.open("GET", options.url + s);
        x.setRequestHeader('UserId', window.uid);
        x.send("");
    }


};

