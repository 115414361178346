/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
window._lista = function (container) {
    var config = {
        core: container.getAttribute("data-syrah-core"),
        uri: container.getAttribute("data-syrah-uri") || "",
        titulo1: container.getAttribute("data-syrah-titulo1"),
        titulo2: container.getAttribute("data-syrah-titulo2"),
        nopagina: container.getAttribute("data-syrah-nopagina")
    };
    var syHeader = getByClass(d, "sy-header")[0];
    config.baseuri = config.uri.replace(/(index\.html)$/ig, "");
    if (config.titulo1 == null) {
        config.titulo1 = "";
    }
    if (config.titulo2 == null) {
        config.titulo2 = "";
    }
    //Se usa para no mostrar el paginado.
    config.pag = (config.nopagina == "" || config.nopagina == null) ? (config.baseuri + "_pag.html") : "";

    if (config.titulo1 != "") {
        var divTitulo = d.createElement("div");
        divTitulo.className = "card-header p-1";
        divTitulo.innerHTML =  "<span class='float-left pl-3 pt-2'>" + config.titulo1 + "</span>";
        container.appendChild(divTitulo);

        if (config.core != "") {
            //Habilitar buscador
            var buttonBuscador = d.createElement("div");
            buttonBuscador.className = "float-right sy-busc2-mini-btn";
            buttonBuscador.innerHTML = "<div class='sy-busc-div2' >" +
                "<input type='search' class='btn border btn-outlinedark' placeholder='Buscar...'></input>" +
                "<i class='sy-busc-icon2 fa fa-search'></i>" +
                "</div>";
            buttonBuscador.onclick = function () {
                window._b_act(syHeader, config, this);
            };
            divTitulo.appendChild(buttonBuscador);
        }
    }
    var ul = d.createElement("ul");
    ul.className = "list-group list-group-flush syrah-lista-body";
    ul.setAttribute("data-syrah-uri", config.uri);
    container.appendChild(ul);
    window._li(ul, function () {
 
    });
    if (config.pag != "") {
        var ulPagina = d.createElement("ul");
        ulPagina.className = "sy-list-pagina card-footer p-0 m-0";
        addCss(ulPagina, "white-space", "nowrap");
        addCss(ulPagina, "overflow", "hidden");

        ulPagina.setAttribute("data-syrah-uri", config.pag);
        container.appendChild(ulPagina);
        addCss(ulPagina, "width", "100%");

        window._li(ulPagina, function () {
            var paginaIndex = ulPagina.getElementsByTagName("a");
            agregarFlechasLateralesPaginador(ulPagina);

            for (var i = 0; i < paginaIndex.length; i++) {
                paginaIndex[i].className = "btn btn-light";
                if (i == 0) {
                    addClass(paginaIndex[i], "w3-uno");
                    addClass(paginaIndex[i], "w3-uno");
                    addClass(paginaIndex[i], "w3-text-shadow");
                    removeClass(paginaIndex[i], "w3-white");
                    addCss(paginaIndex[i], "margin-left", "40px");
                }
                paginaIndex[i].onclick = function () {
                    ul.setAttribute("data-syrah-uri", config.baseuri + this.getAttribute("data-syrah-uri"));
                    var elegido = getByClass(ulPagina, "w3-uno")[0];
                    removeClass(elegido, "w3-uno");
                    removeClass(elegido, "w3-text-shadow");
                    addClass(elegido, "w3-white");
                    removeClass(this, "w3-white");
                    addClass(this, "w3-uno");
                    addClass(this, "w3-text-shadow");
                    window._li(ul, function () {

                    });
                };
            }
        });
    }

};
window._listaConEstilo = function (container) {
    var config = {
        core: container.getAttribute("data-syrah-core"),
        uri: container.getAttribute("data-syrah-uri"),
        /*titulo1: container.getAttribute("data-syrah-titulo1"),
         titulo2: container.getAttribute("data-syrah-titulo2"),*/
        nopagina: container.getAttribute("data-syrah-nopagina")
    };


    var syHeader = getByClass(d, "sy-header")[0];
    config.baseuri = config.uri.replace(/(index\.html)$/ig, "");

    //Se usa para no mostrar el paginado.
    config.pag = (config.nopagina == "" || config.nopagina == null) ? (config.baseuri + "_pag.html") : "";

    /*if (config.titulo1 == null) {
        config.titulo1 = "";
    }
    if (config.titulo2 == null) {
        config.titulo2 = "";
    }
    //Se usa para no mostrar el paginado.
    config.pag = (config.nopagina == "" || config.nopagina == null) ? (config.baseuri + "_pag.html") : "";

    if (config.titulo1 != "") {
     var divTitulo = d.createElement("div");
     divTitulo.className = "sy-list-head w3-bar w3-padding-8";
     divTitulo.innerHTML = "<span class=\"w3-bar-item\">" + config.titulo1 + "</span>";
     container.appendChild(divTitulo);
     
     if (config.core != "") {
     //Habilitar buscador
     var buttonBuscador = d.createElement("span");
     buttonBuscador.className = "w3-bar-item w3-right w3-margin-right w3-tooltip";
     buttonBuscador.innerHTML = "<i class='sy-busc-icon fa fa-search w3-large w3-transparent w3-btn'></i>";
     buttonBuscador.onclick = function () {
     window._b_act(syHeader, config);
     };
     divTitulo.appendChild(buttonBuscador);
     }
     }
     */

    var buttonBuscador = d.getElementById("btnbuscador");
    buttonBuscador.onclick = function () {
        window._b_act(syHeader, config);
    };


    var ul = d.createElement("ul");
    //ul.className = "w3-ul syrah-lista-body"; //
    ul.setAttribute("data-syrah-uri", config.uri);
    container.appendChild(ul);
    window._li(ul, function () {

    });
    if (config.pag != "") {
        var ulPagina = d.createElement("ul");
        ulPagina.className = "w3-ul w3-card-2 w3-display-bottommiddle sy-list-pagina";
        addCss(ulPagina, "white-space", "nowrap");
        addCss(ulPagina, "overflow", "hidden");

        ulPagina.setAttribute("data-syrah-uri", config.pag);
        container.appendChild(ulPagina);
        addCss(ulPagina, "width", "100%");

        window._li(ulPagina, function () {
            var paginaIndex = ulPagina.getElementsByTagName("a");
            agregarFlechasLateralesPaginador(ulPagina);

            for (var i = 0; i < paginaIndex.length; i++) {
                paginaIndex[i].className = "btn btn-light";
                if (i == 0) {
                    addClass(paginaIndex[i], "w3-uno");
                    addClass(paginaIndex[i], "w3-uno");
                    addClass(paginaIndex[i], "w3-text-shadow");
                    removeClass(paginaIndex[i], "w3-white");
                    addCss(paginaIndex[i], "margin-left", "40px");
                }
                paginaIndex[i].onclick = function () {
                    ul.setAttribute("data-syrah-uri", config.baseuri + this.getAttribute("data-syrah-uri"));
                    var elegido = getByClass(ulPagina, "w3-uno")[0];
                    removeClass(elegido, "w3-uno");
                    removeClass(elegido, "w3-text-shadow");
                    addClass(elegido, "w3-white");
                    addClass(this, "w3-uno");
                    addClass(this, "w3-text-shadow");
                    window._li(ul, function () {

                    });
                };
            }
        });
    }
}
function agregarFlechasLateralesPaginador(ulPagina) {
    var fleft = d.createElement("button");
    var fright = d.createElement("button");
    ulPagina.appendChild(fleft);
    ulPagina.appendChild(fright);
    fleft.innerHTML = "&laquo;";
    fleft.className = "btn btn-secondary";
    fleft.onclick = function () {
        ulPagina.scrollLeft -= this.clientWidth * 1;
        fleft.style.left = ulPagina.scrollLeft + "px";
        fright.style.right = (-ulPagina.scrollLeft) + "px";
    };
    addCss(fleft, "position", "absolute");
    addCss(fleft, "left", "0px");
    addCss(fright, "position", "absolute");
    addCss(fright, "right", "0px");

    fright.innerHTML = "&raquo;";
    fright.className = "btn btn-secondary";
    fright.onclick = function () {
        ulPagina.scrollLeft += this.clientWidth * 1;
        fleft.style.left = ulPagina.scrollLeft + "px";
        fright.style.right = (-ulPagina.scrollLeft) + "px";
    };
    setTimeout(function (_p) {
        addCss(_p.ulPagina, "padding-left", _p.fleft.clientWidth + "px!important");
        addCss(_p.ulPagina, "padding-right", _p.fright.clientWidth + "px!important");

    }, 5, {
            ulPagina: ulPagina,
            fleft: fleft,
            fright: fright
        });
    addCss(ulPagina, "display", "relative");

}

