
//Función para ejecutar un formulario online
window._f = function (btn, guid) {
    
    var data = {
        or: guid
    };

    var formValues=jQuery("#o_"+guid+" .syrah-form-values")[0];
    var formItems = getByClass(formValues,"syrah-form-item");
    for (var fi = 0; fi < formItems.length; fi++) {
        data[formItems[fi].attributes["name"].value] = formItems[fi].value;
        if(formItems[fi].attributes["name"].value=="_geopos"){
            if(window._lastgeopos){
                data[formItems[fi].attributes["name"].value] = window._lastgeopos.lat+";"+window._lastgeopos.lon;
            }
        }
    }
    var form =  jQuery("#o_"+guid);
    if (jQuery(form).valid())
    {
        window._p(
                {
                    element: formValues,
                    url: syrah_ol,
                    tipo: 'GET',
                    data: data,
                    callback: function (hres) {
                        var jres = JSON.parse(hres);
                        var divRes = getByClass(form[0],"syrah-result")[0];
                        divRes.innerHTML = "";
                        addClass(divRes,"container");
                        var table = d.createElement("table");
                        table.className = "syra-result-grid table";
                        if (jres.error == "" && jres.res != undefined && jres.res.length >= 0) {

                            function addHeaders(table, keys) {
                                var row = table.insertRow();
                                for (var i = 0; i < keys.length; i++) {
                                    var cell = row.insertCell();
                                    cell.className="w3-uno";
                                    cell.appendChild(d.createTextNode(keys[i]));
                                }
                            }

                            var table = d.createElement('table');
                            //table.className = "w3-table-all w3-medium";
                            table.className = "table table-bordered table-striped mt-4";
                            for (var i = 0; i < jres.res.length; i++) {

                                var child = jres.res[i];
                                if (i === 0) {
                                    addHeaders(table, Object.keys(child));
                                }
                                var row = table.insertRow();
                                Object.keys(child).forEach(function (k) {
                                    var cell = row.insertCell();
                                    cell.className= "w3-hover-red w3-text-black";
									var div=d.createElement("div");
									div.innerHTML=child[k];
                                    cell.appendChild(div);
                                });
                            }

                            divRes.appendChild(table);
                        } else {
                            divRes.innerHTML = "<p class='w3-red w3-text-white'>El servicio está tardando más de lo esperado. Por favor, intente más tarde o envíe su consulta a tramite@sanjuan.gov.ar .</p>";
                        }
                        
                    }});
    }

};

window._ifload = function (_this) {
    var divIframe = getByClass(_this,"syrah-asist-ifr")[0];
    if (!divIframe.innerHTML || divIframe.innerHTML == "") {
        var iframe = d.createElement("iframe");
        divIframe.appendChild(iframe);
        window._p(
                {
                    element: divIframe,
                    url: syrah_ol + "/ifs",
                    tipo: 'GET-HTML',
                    data: {u: divIframe.attributes["data-syrah-url"].value},
                    callback: function (html) {
                        window._cleanIframe(divIframe, html);
                    },
                    userstate: 1
                });
    }
};
window._cleanIframe = function (_destino, _html) {
    var divTemp = d.createElement("div");
    divTemp.innerHTML = _html;
    var els = divTemp.getElementsByTagName("*");
    for (var e in els) {
        els[e].style = "";
    }
    _destino.innerHTML = divTemp.innerHTML;

    var forms = _destino.getElementsByTagName("form");
    for (var f in forms) {
        forms[f].divIframe = _destino;
        forms[f].onsubmit = window._ifsubmit;

    }
};
window._ifsubmit = function () {
    var _form = this;
    var elements = _form.getElementsByTagName("*");
    var formData = new FormData();
    for (var i = 0; i < elements.length; i++)
    {
        if (elements[i].name != undefined) {
            formData.append(elements[i].name, elements[i].value);
        }
    }
    formData.append("u", _form.divIframe.attributes["data-syrah-url"].value);
    var x = _getX();
    x.open("POST", _form.attributes["action"].value);
    x.onreadystatechange = function () {
        if (x.readyState == XMLHttpRequest.DONE) {
            window._cleanIframe(_form.divIframe, x.responseText);
        }
    };
    x.onerror = function () {
        window._cleanIframe(_form.divIframe, x.statusText);
    };
    x.onabort = function () {
        window._cleanIframe(_form.divIframe, x.statusText);
    };
    x.send(formData);
    return false;
};
