// nito

window._li = function (syrahItemDiv, callback) {

    var uri = syrahItemDiv.attributes["data-syrah-uri"].value;
    var isAbsoluta = uri.indexOf("http");
    if (isAbsoluta == -1) {
        uri = "gen" + syrahItemDiv.attributes["data-syrah-uri"].value;
    }
    window._p(
        {
            element: syrahItemDiv,
            url: uri,
            tipo: 'GET-HTML',
            data: {},
            callback: function (_hres) {
                syrahItemDiv.innerHTML = _hres;
                var elements = document.querySelectorAll('[data-include]'), i;
                for (i in elements) {
                    if (elements[i].hasAttribute && elements[i].hasAttribute('data-include')) {
                        fragment(elements[i], elements[i].getAttribute('data-include'));
                    }
                }

                if (callback) {
                    callback(syrahItemDiv);
                }
            }
        }
    );
}


window._liPage = function (btn, parent, uri, callback) {
    uri = "gen" + uri.replace(/index([0-9]{0,100})\.html/, "index" + btn.pagina + ".html");
    window._p(
        {
            element: parent,
            url: uri,
            tipo: 'GET-HTML',
            data: {},
            callback: function (_hres) {
                if (_hres != "") {
                    var tmpDoc = d.createElement("div");
                    tmpDoc.innerHTML = _hres;
                    for (var i = 0; i < tmpDoc.childNodes.length; i++) {
                        var child = tmpDoc.childNodes[i];
                        tmpDoc.removeChild(tmpDoc.childNodes[i]);
                        parent.appendChild(child);
                    }
                    if (getByClass(parent, "sy-load-mas").length > 0) {
                        parent.removeChild(btn);
                    }
                    btn.pagina += btn.paso;

                    parent.appendChild(btn);
                    var elements = document.querySelectorAll('[data-include]');
                    var i;
                    for (i in elements) {
                        if (elements[i].hasAttribute && elements[i].hasAttribute('data-include')) {
                            fragment(elements[i], elements[i].getAttribute('data-include'));
                        }
                    }
                    if (callback) {
                        callback(parent);
                    }
                } else {
                    parent.removeEventListener("scroll", null);
                    btn.removeEventListener("click", null);
                    btn.style.display = "none";
                }
            }
        }
    );
}

function fragment(el, url) {
    var localTest = /^(?:file):/,
        xmlhttp = new XMLHttpRequest(),
        status = 0;

    xmlhttp.onreadystatechange = function () {
        /* if we are on a local protocol, and we have response text, we'll assume
*  				things were sucessful */
        if (xmlhttp.readyState == 4) {
            status = xmlhttp.status;
        }
        if (localTest.test(location.href) && xmlhttp.responseText) {
            status = 200;
        }
        if (xmlhttp.readyState == 4 && status == 200) {
            el.outerHTML = xmlhttp.responseText;
        }
    }

    try {
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    } catch (err) {
        /* todo catch error */
    }
}
