//preguntale a nito
window._ulorg = function (syrahItemDiv, callback) {
    
    var uri = "gen" + syrahItemDiv.attributes["data-syrah-uri2"].value;
    window._p(
            {
                element: syrahItemDiv,
                url: uri,
                tipo: 'GET',
                data: {},
                callback: function (_hres) {
                    syrahItemDiv.innerHTML = _hres;
                    if (callback) {
                        callback(syrahItemDiv);
                    }
                }
            }
    );
};
//nito
window._o = function (container, vertical) {
    var divcol = d.createElement("div");
    divcol.setAttribute("class", "row");
    divcol.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);

    container.appendChild(divcol);
    window._ulorg(container.children[0], function () {
        
    });
};
