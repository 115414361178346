window.locationManager = window.locationManager || (function () {
    var opciones;
    function _enrutar() {
        var hayHash = window.location.hash;
        if (hayHash != "") {
            var hash = window.atob(hayHash.substring(1));
            var hashArray = hash.split("|");

            if (hashArray[0] == "b") {
                var inputBuscadores = getByClass(d, "syrah-bus-inp");
                if (inputBuscadores.length > 0) {
                    inputBuscadores[0].value = hashArray[1];
                    inputBuscadores[0].ondeeplinking = true;
                    inputBuscadores[0].onkeyup();
                    var contenedor = getByClass(d, "syrah-menu")[0];
                    removeClass(contenedor, "modoMenu");
                    addClass(contenedor, "modoBuscador");
                    inputBuscadores[0].ondeeplinking = undefined;
                }

            } else if (hashArray[0] == "v") {
                window._v(undefined, hashArray[1], hashArray[2], hashArray[3]);
            }

        } else {
            var modales = document.querySelectorAll("[id=modal-container]");
            for (var i = 0; i < modales.length; i++) {
                if (modales[i].close) {
                    modales[i].close();
                }
            }
        }
    }
    ;
    function _checkonhashchange() {
        if ("onpopstate" in window) {
            window.onpopstate = function (e) {
                _enrutar();
            };
        } else {
            if (("onhashchange" in window) && navigator._getBrowser().substring(0, 2) != "IE") {
                if (window.location.hash != prevHash) {
                    prevHash = window.location.hash;
                    _enrutar();
                }
            } else {
                var prevHash = window.location.hash;

                window.setInterval(function () {
                    if (window.location.hash != prevHash) {
                        prevHash = window.location.hash;
                        if (!window.onhashchange) {
                            window.onhashchange = window.onhashchange || function () {
                                _enrutar();
                            };
                        }
                        window.onhashchange();
                    }
                }, 500);
            }
        }
    }
    function _iniciar(_opciones) {
        opciones = _opciones;
        _checkonhashchange();
        _enrutar();
    }
    function _set(_a, _b, _c, _d, _e, _f) {

        if (_a == undefined) {
            _a = "";
        }
        if (_b == undefined) {
            _b = "";
        }
        if (_c == undefined) {
            _c = "";
        }
        if (_d == undefined) {
            _d = "";
        }
        if (_e == undefined) {
            _e = "";
        }
        if (_f == undefined) {
            _f = "";
        }
        var uri = window.btoa(_a + "|" + _b + "|" + _c + "|" + _d + "|" + _e + "|" + _f + "|");
        console.log("Push State: " + uri);
        window.history.pushState(uri, "");
        var uriPrev = window.location.href;
        window.location.hash = uri;
        var uriNueva = window.location.href;
        return (uriPrev != uriNueva);
    }
    function _clear() {
    }
    return {
        enrutar: _enrutar,
        iniciar: _iniciar,
        set: _set,
        clear: _clear
    };

})();