//nito

window._diviconos = function (syrahItemDiv, callback) {
    syrahItemDiv.setAttribute("class", "row");
    var uri = "gen" + syrahItemDiv.attributes["data-syrah-uri2"].value;
    window._p(
            {
                element: syrahItemDiv,
                url: uri,
                tipo: 'GET',
                data: {},
                callback: function (_hres) {
                    syrahItemDiv.innerHTML = _hres;
                    if (callback) {
                        callback(syrahItemDiv);
                    }
                }
            }
    );
};
//nito
window._i = function (container, vertical) {
    var divcol = d.createElement("div");
    divcol.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);


    container.appendChild(divcol);
    window._diviconos(container.children[0], function () {
        var col3 = d.createElement("div");
        col3.setAttribute("class", "col-3");
        container.appendChild(col3);
    });
};

window._i_01 = function (container, vertical) {
    console.log("entre");
    var divcol = d.createElement("div");
    divcol.setAttribute("data-syrah-uri2", container.attributes["data-syrah-uri"].value);


    container.appendChild(divcol);
    window._diviconos(container.children[0], function () {
        var col3 = d.createElement("div");
        col3.setAttribute("class", "col-3");
        container.appendChild(col3);
    });
};
