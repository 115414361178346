/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


window._validaciones = function (_div) {
    var fid=$(_div).find("form").attr("id");
    $("#"+fid).validate({
        debug:true
    });
};