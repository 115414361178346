window._timeline = function (container) {

    window._loadScript("js/timeline_knightlab.js", function () {
        if (document.querySelectorAll("head>link[href*=\"timeline.css\"]").length == 0) {
            var timeline_css = document.createElement("link");
            timeline_css.setAttribute("href", "css/timeline.css");
            timeline_css.setAttribute("rel", "stylesheet");
            document.head.appendChild(timeline_css);
        }
        var uri = container.getAttribute("data-syrah-uri");
        var isAbsoluta = uri.indexOf("http");
        if (isAbsoluta == -1) {
            uri = "gen" + container.getAttribute("data-syrah-uri");
        }
        window._p(
            {
                element: container,
                url: uri,
                tipo: 'GET-HTML',
                data: {},
                callback: function (_hres) {
                    var jres = JSON.parse(_hres);
                    if (jres && jres.length > 0 && jres[0].xls != "") {
                        window.timeline = new TL.Timeline(container, jres[0].xls);
                    }
                    if (callback) {
                        callback(syrahItemDiv);
                    }
                }
            }
        );
    });

};