/**
 * Crea a partir de un contenedor (abajo se coloca el template), encargado de armar el menú y el buscador
 * <nav class="syrah-menu col-6 col-m-12" data-syrah-res-id="seccionBuscador" data-syrah-cores="gobierno">
 <a href="#menu" data-icono="fa fa-home" >San Juan</a>
 <a href="#gobierno" data-icono="fa fa-sitemap" >Gobierno</a>
 </nav>
 * **/
window._header = function (contenedor) {

    window._li(contenedor, function (_contenedor) {

        var mySidenavDer = getByClass(contenedor, "mySidenavDer")[0];
        var myInputBuscar = getByClass(contenedor, "myInputBuscar")[0];
        var w3Close = getByClass(contenedor, "w3-closenav")[0];

        var syHeaderMenu = getByClass(contenedor, "sy-header-menu")[0];
        var syBusInput = getByClass(contenedor, "sy-bus-input")[0];
        var openSideNav = getByClass(contenedor, "openSideNav")[0];
        var mySideNav = getByClass(contenedor, "mySidenav")[0];
        var myOverlay = getByClass(contenedor, "myOverlay")[0];
        var elems = getByClass(d, "syrah-buscador");
        for (var i = 0; i < elems.length; i++) {
            window._b2(elems[i]);
        }

        //Menu open
        if (openSideNav != undefined) {
            openSideNav.addEventListener('click', function () {
                removeClass(mySideNav, "w3-hide");
                addClass(mySideNav, "w3-show-block");
                addClass(mySideNav, "w3-menu-izq");
                removeClass(myOverlay, "w3-hide");
                addClass(myOverlay, "w3-show");
                //myOverlay.style.display = "block";
                window._p({
                    url: "/log/menu_ini"
                });
            });
        }
        //Menu Close
        if (w3Close != undefined) {
            w3Close.addEventListener('click', function () {
                removeClass(mySideNav, "w3-show-block");
                addClass(mySideNav, "w3-hide");
                openSideNav.style.display = "inline-block";
                removeClass(myOverlay, "w3-show");
                addClass(myOverlay, "w3-hide");
                window._p({
                    url: "/log/menu_fin"
                });
                //myOverlay.style.display = "none";
            });
        }


        if (myOverlay != undefined) {
            myOverlay.addEventListener('click', function () {
                w3Close.click();
            });
        }
    });
}

// Add the w3-red class to all opened accordions
function subMenuAccordion(id) {
    var x = document.getElementById(id);
    if (x.className.indexOf("w3-show") == -1) {
        x.className += " w3-show";
        x.previousElementSibling.className += " w3-red";
        addClass(x.previousElementSibling.children[0].children[0], "fa-caret-down");
        removeClass(x.previousElementSibling.children[0].children[0], "fa-caret-right");

    } else {
        x.className = x.className.replace("w3-show", "");
        x.previousElementSibling.className = x.previousElementSibling.className.replace("w3-red", "");
        addClass(x.previousElementSibling.children[0].children[0], "fa-caret-right");
        removeClass(x.previousElementSibling.children[0].children[0], "fa-caret-down");
    }
}

